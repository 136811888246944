export default {
	languages: {
		en: {
			translation: require('./lang/en.json'),
			title: 'English',
			id: 'en',
		},
		vi: {
			translation: require('./lang/vi.json'),
			title: 'Tiếng Việt',
			id: 'vi',
		},
		jp: {
			translation: require('./lang/jp.json'),
			title: '日本',
			id: 'jp',
		},
		kr: {
			translation: require('./lang/kr.json'),
			title: '한국어',
			id: 'kr',
		},
		cn: {
			translation: require('./lang/cn.json'),
			title: '中国',
			id: 'cn',
		},
		cm: {
			translation: require('./lang/cm.json'),
			title: 'ប្រទេសកម្ពុជា',
			id: 'cm',
		},
		th: {
			translation: require('./lang/th.json'),
			title: 'Thai',
			id: 'th',
		},
		id: {
			translation: require('./lang/id.json'),
			title: 'Indonesian',
			id: 'id',
		},
		la: {
			translation: require('./lang/la.json'),
			title: 'Lao',
			id: 'la',
		},
	}
}
