import { Component, createRef } from 'react';
import {formatNumber, showNotification, handleClickOutside, navigateTo, isDesktop, isMobile} from "../../../lib/helper";
import {connect} from "react-redux";
import {fetchingUser, fetchingUserSuccess} from "../../../redux/actions";
import {withRouter} from "react-router-dom";
import api from "../../../lib/api";
import { withTranslation  } from "react-i18next";

class Deposit extends Component{
	constructor(props) {
		super(props);
		this.state = {
			is_show_deposit: false,
			deposit_value: 0,
			deposit_transaction: '',
			is_show_deposit_success: false,
		};
		this.refList = {
			is_show_deposit: createRef(),
			is_show_deposit_success: createRef(),
		};
	}

	componentDidMount() {
		document.addEventListener('click', handleClickOutside.bind(this));
	}

	componentWillUnmount() {
		document.removeEventListener('click', handleClickOutside.bind(this))
	}

	onShowDeposit = () => {
		this.setState({ is_show_deposit: !this.state.is_show_deposit });
	}

	onChangeDeposit = (e) => {
		let deposit_value = parseFloat(e.target.value.toString().replace(/,/g,''));
		this.setState({ deposit_value });
	}

	onRecharge = () => {
		let { vnd_per_usd } = this.props.appReducer;
		api.postRecharge({ amount: Math.floor(this.state.deposit_value / vnd_per_usd) })
			.then(({ data, message, success }) => {
				if(success){
					this.setState({ is_show_deposit_success: true, deposit_transaction: data.code });
				} else {
					showNotification({ message, type: 'danger' });
				}
			});
	}

	render() {
		let { is_show_deposit, deposit_value, deposit_transaction, is_show_deposit_success } = this.state;
		let { minimum_deposit, bank_data, vnd_per_usd } = this.props.appReducer;
		let isDisabledRecharge = isNaN(deposit_value) || deposit_value < minimum_deposit;
		bank_data = (bank_data || '').replace(/\r?\n/g, '<br/>');
		let {t} = this.props;
		return <li data-v-bd82969a="" className="qDeposit" ref={this.refList.is_show_deposit}>
			<button data-v-bd82969a=""
					onClick={navigateTo('deposit').bind(this)}
					className="btn button btn-border btn-qDeposit">
				{isDesktop() && <span data-v-bd82969a="" className="icon">
					<svg data-v-bd82969a=""
						 id="icon-deposit-green"
						 xmlns="http://www.w3.org/2000/svg"
						 width="16.684"
						 height="14.693"
						 viewBox="0 0 16.684 14.693"><g
						data-v-bd82969a="" id="icon-deposit"><path
						data-v-bd82969a="" id="Combined-Shape"
						d="M7.129,4.722c.033-.033.071-.054.1-.086a5.406,5.406,0,0,0-1.792-.327,5.442,5.442,0,1,0,4.734,8.077L7.123,9.332A3.258,3.258,0,0,1,7.129,4.722Zm9.236,1.539a1.082,1.082,0,0,0-1.532-.008L13.6,7.481v-5.9a1.088,1.088,0,0,0-2.177,0v5.9L10.2,6.253a1.089,1.089,0,0,0-1.54,1.54l3.093,3.092a1.067,1.067,0,0,0,.758.311,1.054,1.054,0,0,0,.767-.311l3.093-3.092A1.089,1.089,0,0,0,16.365,6.261Z"
						transform="translate(0 -0.5)" fill="#fff"
						fillRule="evenodd"/></g>
					</svg>
				</span>}
				<span data-v-bd82969a="" className="colorWhite">{ t('fast_recharge') }</span>
			</button>
			{is_show_deposit && <div data-v-110c803e="" data-v-bd82969a="">
				<div data-v-110c803e="" className="dropdown-menu qDeposit show">
					<div data-v-110c803e="" className="wrap">
						{isMobile() && <div data-v-f99ecb42="" onClick={this.onShowDeposit} style={{ fontSize: '35px', marginRight: '5px' }} className="close-page">
							<span data-v-f99ecb42="" className="pe pe-7s-close"/>
						</div>}
						<div data-v-110c803e="" className="block py-2">
							<p data-v-110c803e="" className="colorWhite mb-2 title">Số tiền muốn nạp (VNĐ)</p>
							<form data-v-110c803e="">
								<div data-v-110c803e=""
									 className="form-group position-relative input-amount">
									<input data-v-110c803e="" type="text"
										   placeholder="Vui lòng nhập số tiền"
										   value={formatNumber(deposit_value, true)}
										   onChange={this.onChangeDeposit}
										   className="form-control" />
								</div>
							</form>
							<p data-v-110c803e="" className="noticeDeposit">
								<small data-v-110c803e="">Giá trị nạp tối thiểu là ${formatNumber(minimum_deposit)}</small>
								<br/>
								<small data-v-110c803e="">(Tỉ giá quy đổi $1 = {formatNumber(vnd_per_usd)}VNĐ)</small>
							</p>
							<div data-v-110c803e="" className="sizeLarge">
								<p data-v-110c803e="" className="colorWhite mb-2 title">Tổng sẽ nhận*</p>
								<p data-v-110c803e="" className="receiveAmount colorWhite mb-2">${formatNumber(Math.floor(deposit_value / vnd_per_usd))}</p>
							</div>
							{is_show_deposit_success && <div>
								<div data-v-110c803e="" className="noticeDeposit" style={{ color: 'green', marginBottom: '7px', fontStyle: 'normal' }}>Gửi yêu cầu thành công</div>
								<div data-v-110c803e="" className="noticeDeposit"><small>Vui lòng chuyển khoản đúng {formatNumber(deposit_value)}VNĐ vào tài khoản sau!</small></div>
								<div data-v-110c803e="" className="noticeDeposit deposit" dangerouslySetInnerHTML={{ __html: bank_data }} />
								<div data-v-110c803e="" className="noticeDeposit deposit"><small>Nội dung chuyển:</small> {deposit_transaction}</div>
								<div data-v-110c803e="" className="noticeDeposit deposit">
									<span style={{ color: 'red' }}>Chú ý: Vui lòng ghi đúng nội dung chuyển khoản bên trên để tránh phát sinh lỗi!</span><br/>
									<span style={{ color: 'red' }}>( Vui lòng gọi hotline sau 10phút chuyển khoản nếu vẫn chưa lên điểm )</span>
								</div>
							</div>}
						</div>
						<div data-v-110c803e="" className="block mobileFooter">
							<div data-v-110c803e="" className="sizeSmall">
								<div data-v-110c803e="" className="amount d-flex pdb-15">
																<span data-v-110c803e=""
																	  className="colorWhite mb-2 title">Tổng sẽ nhận*</span>
									<span data-v-110c803e=""
										  className="receiveAmount colorWhite mb-2 text-right"><b
										data-v-110c803e="">${formatNumber(deposit_value / 1000)}</b></span></div>
							</div>
							{!is_show_deposit_success && <button data-v-110c803e="" disabled={isDisabledRecharge}
																 onClick={this.onRecharge}
																 className="btn btn-secondary w-100 btn-confirm mb-2 colorWhite"
																 style={{color: 'white', backgroundColor: isDisabledRecharge ? '#858585' : '#31BAA0' }}>
								<b data-v-110c803e="">Gửi Yêu Cầu</b>
							</button>}
						</div>
					</div>
				</div>
			</div>}
		</li>
	}
}

function mapStateToProps(state) {
	return {
		memberReducer: state.memberReducer,
		appReducer: state.appReducer
	};
}

export default withTranslation()(connect(mapStateToProps, {
	fetchingUser,
	fetchingUserSuccess
})(withRouter(Deposit)));

