import {  Component } from 'react';
import {readURL, setClassNameCondition, showNotification} from "../../lib/helper";

import constants from '../../lib/constants';
import api from "../../lib/api";
import {withTranslation} from "react-i18next";

class KYCModal extends Component{
	constructor(props) {
		super(props);
		this.state = {
			step: 1,
			image: {},
			country: '',
			first_name: props.member?.first_name || '',
			last_name: props.member?.last_name || '',
			id_number: '',
		};
		this.inputPicture = {};
	}

	onChangeInput = (type) => (e) => {
		this.setState({ [type]: e.target.value });
	}

	onBrowsePicture = (side) => () => {
		this.inputPicture[side]?.click();
	}

	onChangeFileInput = (side) => (e) => {
		readURL(e.target.files).then(result => {
			let { image } = this.state;
			let {data} = result || {};
			this.setState({ image: { ...image, [side]: data } });
		});
	}

	onNext = (next_step) => () => {
		let { image, first_name, last_name, id_number, country } = this.state;
		if(next_step >= 2){
			if(!country){
				return showNotification({ message: 'Vui lòng chọn quốc gia', type: 'danger' });
			}
			if(!first_name){
				return showNotification({ message: 'Vui lòng nhập tên', type: 'danger' });
			}
			if(!last_name){
				return showNotification({ message: 'Vui lòng nhập họ', type: 'danger' });
			}
			if(!id_number){
				return showNotification({ message: 'Vui lòng nhập số Chứng minh thư/Hộ chiếu', type: 'danger' });
			}
		}
		if(next_step >= 3){
			if(!image || !image.front || !image.back){
				return showNotification({ message: 'Vui lòng upload đủ 2 mặt Chứng minh thư/Hộ chiếu', type: 'danger' });
			}
			api.postSubmitKYC({ first_name, last_name, id_number, country, front: this.inputPicture['front'].files[0], back: this.inputPicture['back'].files[0] })
				.then(({data, message, success}) => {
					message && showNotification({ message, type: success ? 'success' : 'danger' });
					if(success){
						data && this.props.updateMemberInfo(data);
						this.setState({ step: next_step });
					}
				});
		} else {
			this.setState({ step: next_step });
		}
	}

	render() {
		let { showModal, is_show_modal_kyc, t } = this.props;
		let { step, image, first_name, last_name, id_number, country } = this.state;

		return <div data-v-3bbb76d6="" data-v-9792f17c="" tabIndex="-1" role="dialog" className="modal fade modalEnable show customKYC" style={{ display: is_show_modal_kyc ? 'block' : 'none' }}>
			<div data-v-3bbb76d6="" className="mask" />
			<div data-v-3bbb76d6="" className="modal-dialog simple modal-sm modal-2fa-custom">
				<form data-v-3bbb76d6="">
					<div data-v-3bbb76d6="" className="modal-content">
						<button data-v-3bbb76d6="" type="button" onClick={showModal(false)} aria-label="Close" className="close">
							<img data-v-3bbb76d6=""
								 src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAzMiAzMiI+CiAgICA8ZyBzdHJva2UtbGluZWNhcD0ic3F1YXJlIiBzdHJva2UtbGluZWpvaW49Im1pdGVyIiBzdHJva2Utd2lkdGg9IjIiIGZpbGw9IiNmZmZmZmYiIHN0cm9rZT0iI2ZmZmZmZiIgY2xhc3M9Im5jLWljb24td3JhcHBlciI+CiAgICAgICAgPGcgY2xhc3M9Im5jLWludGVyYWN0X21lbnUtY2xvc2UtMi1vLTMyIj4KICAgICAgICAgICAgPHBhdGggZmlsbD0ibm9uZSIgc3Ryb2tlPSIjZmZmZmZmIiBzdHJva2UtbWl0ZXJsaW1pdD0iMTAiIGQ9Ik0yIDZoMjgiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDAgMTAuMDApIHJvdGF0ZSg0NS4wMCAxNiA2KSIgLz4KICAgICAgICAgICAgPHBhdGggZGF0YS1jb2xvcj0iY29sb3ItMiIgZmlsbD0ibm9uZSIgc3Ryb2tlLW1pdGVybGltaXQ9IjEwIiBkPSJNMiAxNmgyOCIgb3BhY2l0eT0iMCIgLz4KICAgICAgICAgICAgPHBhdGggZmlsbD0ibm9uZSIgc3Ryb2tlPSIjZmZmZmZmIiBzdHJva2UtbWl0ZXJsaW1pdD0iMTAiIGQ9Ik0yIDI2aDI4IiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgwIC0xMCkgcm90YXRlKC00NSAxNiAyNikiIC8+CiAgICAgICAgPC9nPgogICAgPC9nPgo8L3N2Zz4="
								 alt="" /></button>
						<div data-v-3bbb76d6="" className="modal-body">
							<div data-v-3bbb76d6="" className="subtitle mb-4">
								<h3 data-v-3bbb76d6="" className="color-white font-weight-bold text-center mb-0">{t('account_verification')}</h3></div>

							<div data-v-23b07f93="" className="steps text-center mb-4">
								<div data-v-23b07f93="" className="line" />
								<div data-v-23b07f93="" className="step step1"><span data-v-23b07f93=""
																					 className={setClassNameCondition(step >= 1, 'active', '', 'idx pointer')}>1</span><span
									data-v-23b07f93="">{t('personal_information')}</span></div>
								<div data-v-23b07f93="" className="step step2"><span data-v-23b07f93=""
																					 className={setClassNameCondition(step >= 2, 'active', '', 'idx pointer')}>2</span><span
									data-v-23b07f93="">{t('identity_verification')}</span></div>
								<div data-v-23b07f93="" className="step step3"><span data-v-23b07f93=""
																					 className={setClassNameCondition(step >= 3, 'active', '', 'idx pointer')}>3</span><span
									data-v-23b07f93="">{t('consider')}</span></div>
							</div>

							<div data-v-3bbb76d6="" className="boxContent g_auth">
								{step === 1 && <div data-v-3bbb76d6="" className="row h-100">
									<div data-v-3bbb76d6="" className="col-12 d-flex flex-column">
										<div data-v-3bbb76d6="" className="form-group"><label
											data-v-3bbb76d6="" className="color-white-50">{t('nation')}</label>
											<select name="" onChange={this.onChangeInput('country')} value={country} className="form-control">
												<option key={'s'} >{t('choose_nation')}</option>
												{constants.COUNTRIES.map(({name, cc}, index) => {
													return <option value={cc} key={index} >{name}</option>
												})}
											</select>
										</div>

										<div data-v-3bbb76d6="" className="row">
											<div data-v-3bbb76d6="" className="col-md-6 col-xs-12">
												<div data-v-3bbb76d6="" className="form-group verification-code"><label
													data-v-3bbb76d6="" className="color-white-50">{t('first_name')}</label>
													<div data-v-3bbb76d6="" className="input-group">
														<div data-v-3bbb76d6="" className="position-relative">
															<input data-v-3bbb76d6="" type="text"
																   placeholder={t('first_name')}
																   value={first_name || ''}
																   onChange={this.onChangeInput('first_name')}
																   className="form-control"/></div>
													</div>
												</div>
											</div>
											<div data-v-3bbb76d6="" className="col-md-6 col-xs-12">
												<div data-v-3bbb76d6="" className="form-group verification-code"><label
													data-v-3bbb76d6="" className="color-white-50">{t('last_name')}</label>
													<div data-v-3bbb76d6="" className="input-group">
														<div data-v-3bbb76d6="" className="position-relative">
															<input data-v-3bbb76d6="" type="text"
																   placeholder={t('last_name')}
																   value={last_name || ''}
																   onChange={this.onChangeInput('last_name')}
																   className="form-control"/></div>
													</div>
												</div>
											</div>
										</div>

										<div data-v-3bbb76d6="" className="form-group"><label
											data-v-3bbb76d6="" className="color-white-50">{t('number')} {t('passport_number')}</label>
											<div data-v-3bbb76d6="" className="position-relative"><input
												data-v-3bbb76d6="" type="text"
												value={id_number || ''}
												onChange={this.onChangeInput('id_number')}
												className="form-control" />
											</div>
										</div>
										<div data-v-3bbb76d6="" className="listTools mt-auto">
											<div data-v-3bbb76d6="" className="row">
												<div data-v-3bbb76d6=""
													 className="col-md-12" style={{ textAlign:'center' }}>
													<button data-v-3bbb76d6="" type="button"
															onClick={this.onNext(2)}
															className="btn btn-primary btn-next w-100" style={{ maxWidth: '100%'}}>{t('next_step')}</button>
												</div>
											</div>
										</div>
									</div>
								</div>}
								{step === 2 && <div data-v-3bbb76d6="" className="row h-100">
									<div data-v-3bbb76d6="" className="col-12 d-flex flex-column">
										<div data-v-23b07f93="" className="row m-0 mb-3">
											<div data-v-23b07f93="" className=""><p>1. {t('id_format_1')}.</p><p>2. {t('id_format_2')}.</p></div>
										</div>

										<div data-v-3bbb76d6="" className="form-group"><label
											data-v-3bbb76d6="" className="color-white-50">{t('front')} {t('passport_number')}</label>
											<div data-v-3bbb76d6="" className="row">
												<div data-v-3bbb76d6="" className="col-md-6 col-xs-12 mb-2">
													<label data-v-23b07f93="" onClick={this.onBrowsePicture('front')} className="sample pointer">
														{image && image.front ? <img className='id_image' src={image.front} alt=""/> : <span data-v-23b07f93="" className="iconUpload" />}
													</label>
													<input data-v-23b07f93="" type="file" style={{ display: 'none' }}
														   onChange={this.onChangeFileInput('front')}
														   ref={input => this.inputPicture['front'] = input}
														   accept="image/png, image/jpg, image/jpeg" />
												</div>
												<div data-v-3bbb76d6="" className="col-md-6 col-xs-12 mb-2">
													<div data-v-23b07f93="" className="sample" >
														<img className='id_image' src="images/id_front.svg" alt=""/>
													</div>
												</div>
											</div>
										</div>

										<div data-v-3bbb76d6="" className="form-group"><label
											data-v-3bbb76d6="" className="color-white-50">{t('back')} {t('passport_number')}</label>
											<div data-v-3bbb76d6="" className="row">
												<div data-v-3bbb76d6="" className="col-md-6 col-xs-12 mb-2">
													<label data-v-23b07f93="" onClick={this.onBrowsePicture('back')} className="sample pointer">
														{image && image.back ? <img className='id_image' src={image.back} alt=""/> : <span data-v-23b07f93="" className="iconUpload" />}
													</label>
													<input data-v-23b07f93="" type="file" style={{ display: 'none' }}
														   onChange={this.onChangeFileInput('back')}
														   ref={input => this.inputPicture['back'] = input}
														   accept="image/png, image/jpg, image/jpeg" />
												</div>
												<div data-v-3bbb76d6="" className="col-md-6 col-xs-12 mb-2">
													<div data-v-23b07f93="" className="sample" >
														<img className='id_image' src="images/id_back.svg" alt=""/>
													</div>
												</div>
											</div>
										</div>

										<div data-v-3bbb76d6="" className="listTools mt-auto">
											<div data-v-3bbb76d6="" className="row">
												<div data-v-3bbb76d6=""
													 className="col-md-12" style={{ textAlign:'center' }}>
													<button data-v-3bbb76d6="" type="button"
															onClick={this.onNext(3)}
															className="btn btn-primary btn-next w-100" style={{ maxWidth: '100%'}}>{t('next_step')}</button>
												</div>
											</div>
										</div>
									</div>
								</div>}
								{step === 3 && <div data-v-3bbb76d6="" className="row h-100">
									<div data-v-3bbb76d6="" className="col-12 d-flex flex-column">

										<div data-v-23b07f93="" className="row m-0 mb-3"><h5 data-v-23b07f93=""
																							 className="w-100 text-center colorGreen">{t('submitted_successfully')}</h5><p data-v-23b07f93="" className="text-center">{t('submitted_successfully_des')}</p>
										</div>

										<div data-v-3bbb76d6="" className="listTools mt-auto">
											<div data-v-3bbb76d6="" className="row">
												<div data-v-3bbb76d6=""
													 className="col-md-12" style={{ textAlign:'center' }}>
													<button data-v-3bbb76d6="" type="button"
															onClick={showModal(false)}
															className="btn btn-primary btn-next w-100" style={{ maxWidth: '100%'}}>{t('completed')}</button>
												</div>
											</div>
										</div>
									</div>
								</div>}
							</div>
						</div>
					</div>
				</form>
			</div>
		</div>
	}
}

export default withTranslation()(KYCModal);
