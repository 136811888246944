export const KEY = {
	USER_INFORMATION: 'USER_INFORMATION',
	APP_CONFIG: 'APP_CONFIG',
	REF_USERNAME: 'REF_USERNAME',
	LANGUAGE: 'LANGUAGE',
}

function decodeData(data, defaultValue = null){
	try{
		let { value, type } = JSON.parse(data);
		switch (type){
			case 'undefined':{
				value = undefined;
			} break;
			case 'number': {
				value = parseFloat(value);
			} break;
			case 'boolean': {
				value = value === 'true';
			} break;
			case 'object':{
				value = JSON.parse(value);
			} break;
		}
		return value;
	} catch (e){
		return defaultValue;
	}
}

function encodeData(value){
	let type = typeof value;
	switch (type){
		case 'undefined':{
			value = '';
		} break;
		case 'number':
		case 'boolean': {
			value = value.toString();
		} break;
		case 'object':{
			value = JSON.stringify(value);
		} break;
	}
	return JSON.stringify({ type, value });
}

export function getItem(key, defaultValue = null){
	let value = localStorage.getItem(key);
	return value ? decodeData(value) : defaultValue
}

export function setItem(key, value){
	return localStorage.setItem(key, encodeData(value));
}

export function removeItem(key){
	return localStorage.removeItem(key);
}

export async function getMultiple(keys = [] | {}){
	let objs = {};
	if(Array.isArray(keys)){
		keys.forEach(key => {
			objs[key] = getItem(key)
		});
	} else {
		Object.keys(keys).forEach(key => {
			objs[key] = getItem(key, keys[key])
		});
	}
	return objs;
}

export async function setMultiple(keys_values = {}){
	return Object.keys(keys_values).map(key => setItem(key, keys_values[key]));
}

export async function multiRemove(keys = []){
	return keys.map(key => removeItem(key));
}

export async function clear(){
	return localStorage.clear();
}
