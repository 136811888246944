import './assets/css/menu.css';
import Header from './components/Header';
import LeftSidebar from "./components/LeftSidebar";
import Trading from "./components/trading";
import Home from "./components/home";
import Login from "./components/login";
import Register from "./components/register";
import ForgotPassword from "./components/forgot";
import ForgetPassword from "./components/forgot/forget";
import Wallet from "./components/wallet";
import Profile from "./components/profile";
import Dashboard from "./components/dashboard";
import Affiliate from "./components/affiliate";
import Deposit from "./components/deposit";
import Transfer from "./components/transfer";
import Challenge from "./components/challenge";
import {connect} from "react-redux";
import {Component} from "react";
import api from './lib/api'

import {updateAppConfigAction, fetchingUser, fetchingConfig, updateAppWindowWidthAction, fetchingTransactionAction} from './redux/actions'

import {
	Switch,
	Route,
	withRouter
} from "react-router-dom";
import {changeLanguage, getCurrentWrapperClass, isDesktop} from "./lib/helper";
import i18n from "i18next";
import io from "socket.io-client";
import {KEY, getItem, setItem} from "./lib/database";
import PopupCongratulation from "./lib/custom/PopupCongratulation";

class App extends Component{
	constructor(props) {
		super(props);
	}

	componentWillMount() {
		this.unlisten = this.props.history.listen((location, action) => {
			this.props.updateAppConfigAction({ wrapper_class: getCurrentWrapperClass() });
		});
		let lang = getItem(KEY.LANGUAGE);
		if(!lang){
			lang = 'en';
			setItem(KEY.LANGUAGE, lang);
		}
		i18n.changeLanguage(lang);
	}

	componentWillUnmount() {
		this.unlisten && this.unlisten();
	}

	componentDidMount() {
		this.props.fetchingConfig();
		this.props.updateAppConfigAction({ wrapper_class: getCurrentWrapperClass() });
		this.props.fetchingUser();
		setTimeout(() => {
			let { login_token } = this.props.memberReducer || {};
			window.socket = io(this.props.appReducer.socket_link, { query: { login_token } });
			window.socket.on('WE_RESULT', ({ bet_id }) => {
				this.props.fetchingUser();
				api.getBetResult(bet_id).then(({ data, success }) => {
					if(success){
						let { is_win, value } = data;
						this.props.memberReducer?.enable_sound && window.document.getElementById(is_win ? 'win' : 'lose')?.play();
						if(is_win && value > 0){
							this.props.updateAppConfigAction({ win_value: value });
							setTimeout(() => {
								this.props.updateAppConfigAction({ win_value: 0 });
							}, 5000);
						}
						this.props.fetchingTransactionAction();
					}
				});
			});
		}, 1000);
	}

	render(){
		let { wrapper_class, app_loading } = this.props.appReducer;
		if(app_loading){
			return <div className="loader-container">
				<div className="loader" />
			</div>
		}
		let { id } = this.props.memberReducer || {};
		return (
			<div data-v-b0e45e8c="" className={'white-theme vi ' + (isDesktop() ? '' : 'mobile-version')}>
				<div data-v-b0e45e8c="">
					<div data-v-b0e45e8c="" className={'wrapper ' + wrapper_class}>
						<div data-v-b0e45e8c="" className="spaceTop" />
						<Header />
						<main data-v-b0e45e8c="" className={id ? 'hasSidebar' : ''}>
							{id && <LeftSidebar />}
							<Switch>
								<Route path="/login" component={Login} />
								<Route path="/register" component={Register} />
								<Route path="/forgot-password" component={ForgotPassword} />
								<Route path="/forget-password" component={ForgetPassword} />
								<Route path="/trading" component={Trading} />
								<Route path="/wallet" component={Wallet} />
								<Route path="/profile" component={Profile} />
								<Route path="/dashboard" component={Dashboard} />
								<Route path="/affiliate" component={Affiliate} />
								<Route path="/deposit" component={Deposit} />
								<Route path="/transfer" component={Transfer} />
								<Route path="/challenge" component={Challenge} />
								<Route exact path="/" component={Home} />
							</Switch>
						</main>
						<PopupCongratulation />
					</div>
				</div>
				<div data-v-c2bd503a="" data-v-b0e45e8c="" />
				<div data-v-68cf3fda="" data-v-5b2de64c="" className="blockSound">
					<audio data-v-68cf3fda="" id="lose" preload="auto">
						<source data-v-68cf3fda="" src="audio/lose.mp3"
								type="audio/mpeg" />
					</audio>
					<audio data-v-68cf3fda="" id="win" preload="auto">
						<source data-v-68cf3fda="" src="audio/win.mp3"
								type="audio/mpeg" />
					</audio>
					<audio data-v-68cf3fda="" id="bet" preload="auto">
						<source data-v-68cf3fda="" src="audio/order.mp3"
								type="audio/mpeg" />
					</audio>
				</div>
			</div>
		);
	}
}

function mapStateToProps(state) {
	return {
		memberReducer: state.memberReducer,
		appReducer: state.appReducer,
	};
}

export default withRouter(connect(mapStateToProps, {
	updateAppConfigAction,
	fetchingUser,
	fetchingConfig,
	updateAppWindowWidthAction,
	fetchingTransactionAction
})(App));
