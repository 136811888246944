import { Component } from 'react';

import Highcharts from 'highcharts/highstock';
import Indicators from 'highcharts/indicators/indicators';
import HighchartsReact from 'highcharts-react-official';
import highchartsMore from "highcharts/highcharts-more.js";

import {formatDecimalNotRound, getDeviceInfo, getSocket, isDesktop, setClassNameCondition} from "../../lib/helper";
import { connect } from 'react-redux';
import {updateChartAction, fetchingUser, updateAppConfigAction, fetchingTransactionAction} from "../../redux/actions";
import {getTradeChartDefault, getSMADefault, getChartHeight} from "../../lib/trade_default_data";
import AnalysisWrapper from "./partial/AnalysisWrapper";
import $ from "jquery";
import _ from "lodash";
import {withTranslation} from "react-i18next";

Indicators(Highcharts);
highchartsMore(Highcharts);

const TABS = {
	indicator: 'indicator',
	last_result: 'last_result',
};

const { deviceVersion } = getDeviceInfo();
const columnLength = deviceVersion === 'pc' ? 62 : 15;

class LeftContent extends Component{
	constructor(props) {
		super(props);
		this.state = {
			options: getTradeChartDefault(),
			tab_selected: TABS.last_result,
			trading_data: props.trading_data,
			allow_chart_update: true
		};
		this.init_chart = false;
	}

	updateDrawChart = (btcDataList, redraw) => {
		if(!btcDataList || !btcDataList.length){
			return false;
		}
		let n = 16.44;
		"pc" !== deviceVersion && (n = 17.44);
		let a = this.refChart.container.current.clientWidth - 90;
		let _columnLength = Math.floor(a / n);
		let o = btcDataList[0].x;
		if (_columnLength < btcDataList.length) {
			let r = _.cloneDeep(btcDataList).slice(-_columnLength);
			r.length && (o = r[0].x);
		}
		let createDateTime = btcDataList[btcDataList.length - 1].x;
		this.refChart.chart.xAxis[0].setExtremes(o, createDateTime, redraw);
	}

	componentWillReceiveProps(nextProps, nextContext) {
		let {trading_data} = nextProps;
		if(!this.init_chart || !trading_data.length){
			this.init_chart = true;
			let btcDataList = [];
			let volDataList = [];
			let dataForOption = _.takeRight(trading_data, 100);
			dataForOption.forEach(({createDateTime, openPrice, closePrice, baseVolume, highPrice, lowPrice}) => {
				let btcData = {
					x: createDateTime,
					open: openPrice,
					high: highPrice,
					low: lowPrice,
					close: closePrice,
					vol: baseVolume
				};
				let volData = {
					x: btcData.x,
					y: btcData.vol,
					color: btcData.open < btcData.close ? "#31BAA0" : "#FC5F5F"
				};
				btcDataList.push(btcData);
				volDataList.push(volData);
			});
			let options = getTradeChartDefault();
			this.setState({ options, trading_data });
			setTimeout(() => {
				let h = $('.chart-instance').outerHeight() - 60;
				h = h < 150 ? 150 : h;
				$('.sidebarMobile .c_psychological_indicators').css("height", h);
				$('.sidebarMobile .backgroundOverlay').css("height", h);
				$('.sidebarMobile .progress.active').css("width", h - 40);
				this.setState({ allow_chart_update: false });
			}, 2000);

			let setting_value = false;
			getSocket().then(socket => {
				socket.on('WE_PRICE', (we_price) => {
					if(trading_data.length < 5 || setting_value){
						return false;
					}
					setting_value = true;
					let {createDateTime, openPrice, closePrice, baseVolume, highPrice, lowPrice, second} = we_price;
					let btcData = {
						x: createDateTime,
						open: openPrice,
						high: highPrice,
						low: lowPrice,
						close: closePrice,
						vol: baseVolume
					};
					let volData = {
						x: btcData.x,
						y: btcData.vol,
						color: btcData.open < btcData.close ? "#31BAA0" : "#FC5F5F"
					};

					let seriesBTC = this.refChart.chart.series[0];
					let seriesVolume = this.refChart.chart.series[1];
					let latestBTCPoint = seriesBTC.points[seriesBTC.points.length - 1];
					let latestVolumePoint = seriesVolume.points[seriesVolume.points.length - 1];

					this.refChart.chart.yAxis[0].options.plotLines[0].value = closePrice;
					this.refChart.chart.xAxis[0].options.plotLines[0].value = createDateTime;
					let closePriceFormat = formatDecimalNotRound(closePrice, "", 2);
					let secondFormat = '00:' + (second > 9 ? second : "0" + second);
					this.refChart.chart.yAxis[0].options.plotLines[0].label.text = '<div class="plotlineChart d-flex flex-column"><span class="price" id="plotLinePrice">' + closePriceFormat
						+ '</span><span class="time align-self-end" id="timeSecond">'+secondFormat+'</span></div>';
					$('#plotLinePrice').text(closePriceFormat);
					$('#timeSecond').text(secondFormat);

					if(latestBTCPoint && createDateTime === btcDataList[btcDataList.length-1].x){
						latestBTCPoint.update(btcData, false, true);
						latestVolumePoint.update(volData, false, true);
					} else {
						if(createDateTime !== btcDataList[btcDataList.length-1].x){
							btcDataList.push(btcData);
							volDataList.push(volData);
							if(btcDataList.length > 100){
								btcDataList = _.takeRight(_.cloneDeep(btcDataList), 100);
								volDataList = _.takeRight(_.cloneDeep(volDataList), 100);
							}
						}
						seriesBTC.setData(btcDataList, false, true);
						seriesVolume.setData(volDataList, false, true);
						this.updateDrawChart(btcDataList, false);
					}

					if(this.refChart.chart.series.length < 3){
						['sma1', 'sma2'].forEach((smaName, index) => {
							let smaValue = smaName === 'sma1' ? getSMADefault() : getSMADefault({
								name: smaName,
								color: "#E22A67",
								params: {
									index: "1",
									period: 5
								}
							});
							this.refChart.chart.addSeries(smaValue, false, true);
						});
					}
					this.refChart.chart.redraw(true);

					setting_value = false;
				});
			});
		}
	}

	componentWillUnmount() {
		window.socket && window.socket.removeAllListeners("WE_PRICE");
	}

	onChangeTab = (tab) => () => {
		this.setState({ tab_selected: tab })
	}

	render() {
		let { options, allow_chart_update } = this.state;
		let { is_show_transaction } = this.props.appReducer;
		let {t} = this.props;
		return (
			<div data-v-5b2de64c="" id="leftContent" className={'leftContent ' + (is_show_transaction ? '' : 'hideTransaction') + ( isDesktop() ? ' h-100' : '' )}>
				<div data-v-5b2de64c="" className={setClassNameCondition(isDesktop(), 'h-100','','')}>
					<div data-v-49a1c76e="" data-v-5b2de64c="" id="candle-wrap"
						 className="postion-relative chartBox">
						<div data-v-30eeb0ce="" data-v-49a1c76e="" className="indicatorsPopup">
							<div data-v-30eeb0ce="" className="position-relative">
								<button data-v-30eeb0ce="" type="button" className="btnIndicator d-flex align-items-center justify-content-center">
									<img data-v-30eeb0ce="" alt='' src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB3aWR0aD0iMTciIGhlaWdodD0iMjIiIHZpZXdCb3g9IjAgMCAxNyAyMiI+CiAgPGRlZnM+CiAgICA8Y2xpcFBhdGggaWQ9ImNsaXAtcGF0aCI+CiAgICAgIDxyZWN0IGlkPSJSZWN0YW5nbGVfNTA3OCIgZGF0YS1uYW1lPSJSZWN0YW5nbGUgNTA3OCIgd2lkdGg9IjE3IiBoZWlnaHQ9IjIyIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgxNDQuODU4IDEwMi41NTgpIiBmaWxsPSIjZmZmIiBzdHJva2U9IiM3MDcwNzAiIHN0cm9rZS13aWR0aD0iMSIvPgogICAgPC9jbGlwUGF0aD4KICA8L2RlZnM+CiAgPGcgaWQ9Ik1hc2tfR3JvdXBfNjE3IiBkYXRhLW5hbWU9Ik1hc2sgR3JvdXAgNjE3IiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMTQ0Ljg1OCAtMTAyLjU1OCkiIGNsaXAtcGF0aD0idXJsKCNjbGlwLXBhdGgpIj4KICAgIDxnIGlkPSJHcm91cF8yMTUzOSIgZGF0YS1uYW1lPSJHcm91cCAyMTUzOSIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMTM4Ljg0MSAxMTcuMjkyKSByb3RhdGUoLTQ1KSI+CiAgICAgIDxnIGlkPSJHcm91cF8yMTUzOCIgZGF0YS1uYW1lPSJHcm91cCAyMTUzOCIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMCAwKSI+CiAgICAgICAgPHBhdGggaWQ9IlBhdGhfMzUzNTYiIGRhdGEtbmFtZT0iUGF0aCAzNTM1NiIgZD0iTTE5LjkwNy4zYS45ODIuOTgyLDAsMCwwLTEuNDIyLDBMMTYuOTYyLDEuODI4YTIuOTc5LDIuOTc5LDAsMCwwLTMuNTU1LjYwOSwzLjcyOSwzLjcyOSwwLDAsMC0uOTE0LDIuMDMxTDkuMTQxLDUuNDg1LDcuODIxLDMuODZBMS4wMDUsMS4wMDUsMCwxLDAsNi40LDUuMjgybC43MTEuODEzTDMuMjUsNy4yMTFhMS4xLDEuMSwwLDAsMC0uODEzLjgxM0wwLDkuMTQxbDIuNTM5LS40MDZhMS4wMzUsMS4wMzUsMCwwLDAsMS4xMTcuNTA4TDguNzM1LDcuNzE5bDMuODYsMy44Ni0xLjUyNCw1LjA3OGExLDEsMCwwLDAsLjUwOCwxLjExN2wtLjQwNiwyLjUzOSwxLjExNy0yLjQzOEExLjIyNywxLjIyNywwLDAsMCwxMywxNy4xNjVsMS4xMTctMy44Ni43MTEuNzExYTEuMDA1LDEuMDA1LDAsMCwwLDEuNDIyLTEuNDIybC0xLjUyNC0xLjUyNCwxLjAxNi0zLjM1MmEyLjg3MywyLjg3MywwLDAsMCwyLjAzMS0uOTE0LDMuMjU2LDMuMjU2LDAsMCwwLC42MDktMy41NTVsMS41MjQtMS41MjRBLjk4Mi45ODIsMCwwLDAsMTkuOTA3LjNabS02LjcsOS4wNEwxMC45NjksNy4xMSwxMy4yLDYuNGE0LjU4NSw0LjU4NSwwLDAsMCwuNzExLjcxMVptMy4yNS00LjA2M0ExLjAwNSwxLjAwNSwwLDAsMSwxNS4wMzIsMy44NmExLjIxOCwxLjIxOCwwLDAsMSwxLjQyMiwwQS45ODIuOTgyLDAsMCwxLDE2LjQ1NCw1LjI4MloiIGZpbGw9IiNmZmYiLz4KICAgICAgPC9nPgogICAgPC9nPgogIDwvZz4KPC9zdmc+Cg==" />
								</button>
								<div data-v-30eeb0ce="" />
							</div>
						</div>
						<HighchartsReact
							allowChartUpdate={allow_chart_update}
							ref={ref => this.refChart = ref}
							highcharts={Highcharts}
							constructorType={'stockChart'}
							options={options}
							containerProps = {{ className: 'chart-instance', id: 'chart-instance' }}
						/>
						<div data-v-49a1c76e="" className="h-100">
							<div data-v-49a1c76e="" className="h-100 d-flex align-items-center justify-content-center">
								<div data-v-49a1c76e="" className="circle">Không có dữ liệu</div>
							</div>
						</div>
					</div>
					{isDesktop() && <AnalysisWrapper trading_data={this.props.trading_data} />}
				</div>
			</div>
		)
	}
}

function mapStateToProps(state) {
	return {
		chartReducer: state.chartReducer,
		memberReducer: state.memberReducer,
		appReducer: state.appReducer
	};
}

export default withTranslation()(connect(mapStateToProps, {
	updateChartAction,
	fetchingUser,
	updateAppConfigAction,
	fetchingTransactionAction
})(LeftContent));
