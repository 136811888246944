import '../../assets/css/profile.css';

import { Component, createRef } from 'react';
import {connect} from "react-redux";
import {fetchingUser, fetchingUserSuccess} from "../../redux/actions";
import {withRouter} from "react-router-dom";
import api from "../../lib/api";
import {getUrl, handleClickOutside, readURL, setClassNameCondition, showNotification} from "../../lib/helper";
import $ from 'jquery';
import {withTranslation} from 'react-i18next';

import QRCode from 'qrcode';
import KYCModal from "./kyc_modal";

class Profile extends Component{
	constructor(props) {
		super(props);
		this.state = {
			...props.memberReducer,
			current_password: '',
			new_password: '',
			is_show_modal_avatar: false,
			is_show_modal_auth: false,
			is_show_modal_disable_two_fa: false,
			is_show_modal_kyc: false,
			upload_avatar: {},
			secret: '',
			qr_code: '',

			auth_password: '',
			auth_email_code: '',
			auth_two_fa_code: ''
		};
		this.inputPicture = null;

		this.refList = {
			is_show_modal_avatar: createRef(),
			is_show_modal_auth: createRef()
		};
	}

	onChangeInput = (type) => (e) => {
		this.setState({ [type]: e.target.value });
	}

	onSubmitInfo = () => {
		let { first_name, last_name } = this.state;
		api.putChangeProfile({ first_name, last_name })
			.then(({ data, success, message }) => {
				showNotification({ message, type: success ? 'success' : 'danger' });
				this.props.fetchingUserSuccess(data);
			});
	}

	onSubmitPassword = () => {
		let { current_password, new_password } = this.state;
		api.putChangePassword(current_password, new_password)
			.then(({ data, success, message }) => {
				showNotification({ message, type: success ? 'success' : 'danger' });
			});
	}

	onClickChangeAvatar = (is_show) => () => {
		this.setState({ is_show_modal_avatar: is_show });
	}

	onBrowsePicture = () => {
		this.inputPicture?.click();
	}

	onChangeFileInput = (e) => {
		readURL(e.target.files).then(result => {
			let {data, size} = result || {};
			this.setState({ upload_avatar: { data, size } });
		});
	}

	getVerifyStatus = () => {
		let {t} = this.props;
		let { is_verify } = this.state;
		if(is_verify === 1){
			return (<span data-v-3a1169e3="" className="font-18 colorWarning" style={{display: 'flex', gap: '8px'}}><svg
				data-v-3a1169e3="" xmlns="http://www.w3.org/2000/svg" width="17.034" height="18.2"
				viewBox="0 0 17.034 18.2"><g data-v-3a1169e3="" id="timer" transform="translate(0 0.011)"><path
				data-v-3a1169e3="" id="Path_28970" data-name="Path 28970"
				d="M10.171,1.318a1.71,1.71,0,1,0-.14,1.15,7.364,7.364,0,1,1-8.9,7.186,7.248,7.248,0,0,1,.885-3.51.568.568,0,1,0-1-.543A8.369,8.369,0,0,0,0,9.654,8.517,8.517,0,1,0,10.171,1.318Z"
				transform="translate(0 0)" fill="#f49b42" /><path data-v-3a1169e3="" id="Path_28971"
																	   data-name="Path 28971"
																	   d="M7.035,10.445a1.7,1.7,0,1,0,2.41-2.41c-.813-.813-5.915-4.465-6.5-4.883a.568.568,0,0,0-.795.795C2.577,4.535,6.272,9.682,7.035,10.445Z"
																	   transform="translate(0.277 0.413)"
																	   fill="#f49b42" /></g></svg>
                                                               <span> {t('identity_verification_confirming')}</span>
                                                            </span>)
		}

		if(is_verify === 2){
			return  (<span data-v-3a1169e3="" className="font-18 colorSuccess" style={{display: 'flex', gap: '8px'}}>
										<i className='fas fa-check-circle' />
                                                               <span> {t('identity_verification_confirm_done')}</span>
                                                            </span>)
		}

		return <button data-v-9792f17c="" style={{ height: '43px', width: '100%' }} type="button"
					   onClick={this.onShowVerifyModal(true)}
					   className="button wbtn btn-radius btn-large btn-border color-white btn-secondary">{t('identity_verification_confirm')}</button>
	}

	onClickSubmitAvatar = () => {
		if(this.inputPicture?.files && this.inputPicture.files.length){
			api.postChangeAvatar(this.inputPicture.files[0])
				.then(({data, message, success}) => {
					showNotification({ message, type: success ? 'success' : 'danger' });
					if(success){
						this.props.fetchingUserSuccess(data);
						this.setState({ is_show_modal_avatar: false, ...data });
					}
				});
		}
	}

	onShowDisableTwoFa = (is_show) => () => {
		if(is_show) {
			$('body').addClass('modal-open');
		} else {
			$('body').removeClass('modal-open');
			this.setState({ is_two_fa: true });
		}
		this.setState({ is_show_modal_disable_two_fa: is_show })
	}

	onShowAuthModal = (is_show) => () => {
		if(is_show){
			$('body').addClass('modal-open');
			api.getTwoFaKey().then(({ data, success })=> {
				if(success && data){
					let { secret, auth_string } = data;
					QRCode.toString(auth_string).then(qrCode => {
						this.setState({ secret: secret, qr_code: qrCode });
					});
				}
			});
		} else {
			$('body').removeClass('modal-open');
			this.setState({ is_two_fa: false });
		}
		this.setState({ is_show_modal_auth: is_show })
	}

	onChangeTwoFa = () => {
		if(!this.props.memberReducer.is_two_fa && !this.state.is_two_fa){
			this.onShowAuthModal(true)();
		}
		if(this.props.memberReducer.is_two_fa && this.state.is_two_fa){
			this.onShowDisableTwoFa(true)();
		}
		this.setState({ is_two_fa: !this.state.is_two_fa });
	}

	onClickPaste = (type) => (e) => {
		navigator.clipboard.readText()
			.then(text => this.setState({ [type]: text }))
			.catch(e => console.log(e));
	}

	onCopySecret = (e) => {
		e && e.preventDefault();
		navigator.clipboard.writeText( this.state.secret)
			.then(() => {
				showNotification({ type: 'success', message: 'Đã sao chép vào bộ nhớ' });
			})
			.catch(e => {
				showNotification({ type: 'danger', message: e.message });
			})
	}

	onSendOTPEmail = () => {
		api.postOTPEmail().then(({ success, message }) => {
			showNotification({ type: success ? 'success' : 'danger' , message });
		})
	}

	onDisableTwoFa = () => {
		let {auth_password, auth_email_code, auth_two_fa_code} = this.state;
		api.postDisableTwoFa({ auth_password, auth_email_code, auth_two_fa_code })
			.then(({ message, success, data }) => {
				showNotification({ type: success ? 'success' : 'danger', message });
				if(success && data){
					this.onShowDisableTwoFa(false)();
					this.props.fetchingUserSuccess(data);
					this.setState({ ...data });
				}
			});
	}

	onEnableTwoFa = () => {
		let {auth_password, auth_email_code, auth_two_fa_code, secret} = this.state;
		api.postEnableTwoFa({ auth_password, auth_email_code, auth_two_fa_code, secret })
			.then(({ success, message, data }) => {
				showNotification({ type: success ? 'success' : 'danger' , message });
				if(success && data){
					this.onShowAuthModal(false)();
					this.props.fetchingUserSuccess(data);
					this.setState({ ...data });
				}
			})
	}

	onShowVerifyModal = (is_show) => () => {
		if(is_show) {
			$('body').addClass('modal-open');
		} else {
			$('body').removeClass('modal-open');
		}
		this.setState({ is_show_modal_kyc: is_show });
	}

	onUpdateMemberInfo = (data) => {
		if(data){
			this.setState({ ...data });
			this.props.fetchingUserSuccess(data);
		}
	}

	render() {
		let { email, username, first_name, last_name, current_password, new_password, is_show_modal_avatar, is_show_modal_disable_two_fa, is_show_modal_kyc, avatar, upload_avatar, is_two_fa, secret, qr_code, is_show_modal_auth, auth_password, auth_email_code, auth_two_fa_code } = this.state;
		let { first_name: first_name_old, last_name: last_name_old, user_mode } = this.props.memberReducer;
		let _avatar = upload_avatar.data || avatar;
		let is_button_enable = auth_password.length && auth_email_code.length===6 && auth_two_fa_code.length===6;
		let {t} = this.props;
		return (
			<div data-v-b0e45e8c="" id="mainContent">
				<div data-v-b0e45e8c="" className="boxContent">
					<div data-v-9792f17c="" data-v-b0e45e8c="">
						<div data-v-9792f17c="" className="profileContent">
							<div data-v-9792f17c="" className="container">
								<div data-v-9792f17c="" className="panel panel--personal">
									<div data-v-9792f17c="" className="panel-header">
										<h3 data-v-9792f17c="" className="panel-header-title">{t('user_info')}</h3>
									</div>
									<div data-v-9792f17c="" className="panel-body">
										<div data-v-9792f17c="" className="uploadAvatar mb-3">
											<div data-v-9792f17c="" className="d-flex align-items-center">
												<div data-v-de2bfc3e="" data-v-9792f17c="">
													<div data-v-de2bfc3e="" onClick={this.onClickChangeAvatar(true)} className="user-avatar" style={{ backgroundImage: avatar ? `url(${avatar})` : null }}>
														<div data-v-de2bfc3e="" className="overlay" />
														{user_mode === 'unlimited' && <span className='avatar-vip'>vip</span>}
													</div>
													<div data-v-de2bfc3e="" data-backdrop="static" tabIndex="-1"
														 role="dialog" aria-hidden={!is_show_modal_avatar}
														 style={{ display: is_show_modal_avatar ? 'block' : 'none' }}
														 className={'modal fade ' + (is_show_modal_avatar ? 'show' : '')}>
														<div data-v-de2bfc3e=""
															 className="modal-dialog simple modal-sm modal-upload-img">
															<div data-v-de2bfc3e="" className="modal-content p-3">
																<div data-v-de2bfc3e="" className="modal-header d-flex justify-content-between align-items-center">
																	<h3 data-v-de2bfc3e="" className="modalTitle">{t('upload_image_from_pc')}</h3>
																	<button data-v-de2bfc3e="" type="button"
																			onClick={this.onClickChangeAvatar(false)}
																			aria-label="Close" className="btnclose">
																		<img alt=''
																			data-v-de2bfc3e=""
																			src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMSIgaGVpZ2h0PSIyMSIgdmlld0JveD0iMCAwIDIxIDIxIj4KICA8ZyBpZD0iR3JvdXBfMTExMzQiIGRhdGEtbmFtZT0iR3JvdXAgMTExMzQiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC0xMjY4LjM0NSAtMjguMzQ1KSI+CiAgICA8Y2lyY2xlIGlkPSJFbGxpcHNlXzIwMSIgZGF0YS1uYW1lPSJFbGxpcHNlIDIwMSIgY3g9IjEwLjUiIGN5PSIxMC41IiByPSIxMC41IiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgxMjY4LjM0NSAyOC4zNDUpIiBmaWxsPSIjZmZmIiBvcGFjaXR5PSIwLjE5Ii8+CiAgICA8ZyBpZD0iZS1yZW1vdmUiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDEyNzQuNjI0IDM0LjYyNCkiPgogICAgICA8cGF0aCBpZD0iUGF0aF8xMzc4NCIgZGF0YS1uYW1lPSJQYXRoIDEzNzg0IiBkPSJNOS4wNDcsMS4xNzZhLjU2OC41NjgsMCwwLDAtLjgyMiwwTDUuMTExLDQuMjg5LDIsMS4xNzZhLjU2OC41NjgsMCwwLDAtLjgyMiwwLC41NjguNTY4LDAsMCwwLDAsLjgyMkw0LjI4OSw1LjExMSwxLjE3Niw4LjIyNGEuNTY4LjU2OCwwLDAsMCwwLC44MjIuNTMzLjUzMywwLDAsMCwuNDExLjE3NkEuNTMzLjUzMywwLDAsMCwyLDkuMDQ3TDUuMTExLDUuOTM0LDguMjI0LDkuMDQ3YS41NjguNTY4LDAsMCwwLC44MjIsMCwuNTY4LjU2OCwwLDAsMCwwLS44MjJMNS45MzQsNS4xMTEsOS4wNDcsMkEuNTY4LjU2OCwwLDAsMCw5LjA0NywxLjE3NloiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC0xIC0xKSIgZmlsbD0iI2ZmZiIvPgogICAgPC9nPgogIDwvZz4KPC9zdmc+Cg==" />
																	</button>
																</div>
																<div data-v-de2bfc3e="" className="modal-body">
																	<div data-v-de2bfc3e="" className="upload-example">
																		<div data-v-de2bfc3e="">
																			<div data-v-de2bfc3e=""
																				 onClick={this.onBrowsePicture}
																				 className="avatarEmpty text-center">
																				<input data-v-de2bfc3e="" type="file"
																					   onChange={this.onChangeFileInput}
																					   ref={input => this.inputPicture = input}
																					   accept=".jpg,.jpeg,.png"
																					   className="fileUpload" />
																					<div style={{ display: _avatar ? 'none' : 'initial', paddingTop: '45px' }}>
																						<svg data-v-de2bfc3e=""
																							 xmlns="http://www.w3.org/2000/svg"
																							 width="89.156" height="95.1"
																							 viewBox="0 0 89.156 95.1">
																							<g data-v-de2bfc3e=""
																							   id="upload-and-send"
																							   transform="translate(0 0)"
																							   opacity="0.3">
																								<path data-v-de2bfc3e=""
																									  id="Path_34910"
																									  data-name="Path 34910"
																									  d="M25.038,1.016a2.972,2.972,0,0,1,4.476,0l20.8,23.775a2.972,2.972,0,0,1-2.241,4.927H36.188V68.353a2.972,2.972,0,0,1-2.972,2.972H21.329a2.972,2.972,0,0,1-2.972-2.972V29.719H6.469a2.972,2.972,0,0,1-2.235-4.927Z"
																									  transform="translate(17.306 0)"
																									  fill="#fefefe"/>
																								<path data-v-de2bfc3e=""
																									  id="Path_34911"
																									  data-name="Path 34911"
																									  d="M77.269,11V28.831H11.888V11H0V34.775a5.944,5.944,0,0,0,5.944,5.944H83.213a5.944,5.944,0,0,0,5.944-5.944V11Z"
																									  transform="translate(0 54.381)"
																									  fill="#fefefe"/>
																							</g>
																						</svg>
																						<p data-v-de2bfc3e="">{t('click_to_upload')}</p>
																					</div>
																					<img className='img-avatar'
																						 style={{ display: !_avatar ? 'none' : 'initial' }}
																						 src={_avatar} alt=""/>
																				</div>
																			<div data-v-de2bfc3e="" className="imgInfo">
																				{upload_avatar.size > 0 && <p data-v-de2bfc3e=""><span
																					data-v-de2bfc3e=""
																					className="fileSize">{t('size')}: </span>
																					<span data-v-de2bfc3e="" className="size colorGreen">
																						<b data-v-de2bfc3e="">{(upload_avatar.size / 1048576).toFixed(2)} MB</b>
																					</span>
																				</p>}
																				<p data-v-de2bfc3e="">{t('image_limit_des')} 5MB*</p></div>
																			<div data-v-de2bfc3e=""
																				 className="d-flex justify-content-center align-items-center submit">
																				<button type='button' data-v-de2bfc3e=""
																						disabled={!upload_avatar.size}
																						onClick={this.onClickSubmitAvatar}
																						className={'buttonSubmit ' + (!upload_avatar.size ? 'btn-disable' : '')}>
																					{t('update')}
																				</button>
																			</div>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
												<p data-v-9792f17c="" onClick={this.onClickChangeAvatar(true)}
												   className="mt-3 ml-4 text-center button wbtn btn-large btn-border btn-radius px-3 py-2 color-white">
													<i data-v-9792f17c="" className="fas fa-camera mr-2" />
													{t('change_avatar')}
												</p>
											</div>
										</div>
										<form data-v-9792f17c="" disabled="disabled">
											<div data-v-9792f17c="" className="row">
												<div data-v-9792f17c="" className="col-md-6 col-lg-4">
													<div data-v-9792f17c="" className="form-group"><span
														data-v-9792f17c="" className="labelInput">{t('email')}</span>
														<p data-v-9792f17c=""
														   className="winput disabled-input">{email}</p></div>
												</div>
												<div data-v-9792f17c="" className="col-md-6 col-lg-4">
													<div data-v-9792f17c="" className="form-group"><span
														data-v-9792f17c="" className="labelInput">{t('nickname')}</span> <p style={{ background: '#e0e7ff33 0% 0% no-repeat padding-box !important' }}
														data-v-9792f17c="" className="winput disabled-input">{username}</p></div>
												</div>
											</div>
											<div data-v-9792f17c="" className="row mb-4">
												<div data-v-9792f17c="" className="col-md-6 col-lg-4">
													<div data-v-9792f17c="" className="form-group mb-0"><span
														data-v-9792f17c="" className="labelInput">{t('first_name')}</span> <input
														data-v-9792f17c="" type="text" onChange={this.onChangeInput('first_name')} value={first_name || ''}
														maxLength="20" className="form-control mb-2 winput"/>
													</div>
												</div>
												<div data-v-9792f17c="" className="col-md-6 col-lg-4">
													<div data-v-9792f17c="" className="form-group mb-0"><span
														data-v-9792f17c="" className="labelInput">{t('last_name')}</span> <input
														data-v-9792f17c="" type="text" onChange={this.onChangeInput('last_name')} value={last_name || ''}
														maxLength="20" className="form-control mb-2 winput"/>
													</div>
												</div>
											</div>
											<div data-v-9792f17c="" className="bottomTools">
												<div data-v-9792f17c="" className="row">
													<div data-v-9792f17c="" className="col-lg-5 col-md-12 offset-md-0">
														<button data-v-9792f17c="" onClick={this.onSubmitInfo} type="button" disabled={!(first_name !== first_name_old || last_name !== last_name_old)}
																className="button wbtn btn-radius btn-large btn-border color-white btn-secondary">{t('update')} {t('account')}
														</button>
													</div>
												</div>
											</div>
										</form>
									</div>
								</div>
								<div data-v-9792f17c="" className="panel panel--security">
									<div data-v-9792f17c="" className="panel-header"><h3 data-v-9792f17c=""
																						 className="panel-header-title">{t('security')}</h3></div>
									<div data-v-9792f17c="" className="panel-body">

										<div data-v-9792f17c="" className="row changeAuthWrap">
											<div data-v-9792f17c="" className="col-8 col-md-4 mb-2 mb-lg-0">
												<div data-v-9792f17c="" className="d-flex align-items-center h-100 margin5 font-14 color-white">{t('identity_verification')}:</div>
											</div>
											<div data-v-9792f17c="" className="col-md-4 d-sm-block">
												<div data-v-9792f17c="" className="d-flex align-items-center"><p
													data-v-9792f17c=""
													className="colorGray font-14 d-flex flex-column justify-conntent-center mb-0">
													<span data-v-9792f17c="">{t('identity_verification_des')}</span>
												</p></div>
											</div>
											<div data-v-9792f17c="" className="col-md-4 col-xs-12">
												<div data-v-9792f17c="" className="d-flex align-items-center mt-3">
													{this.getVerifyStatus()}
												</div>
											</div>
										</div>
									</div>
								</div>
								<div data-v-9792f17c="" className="panel panel--security">
									<div data-v-9792f17c="" className="panel-header"><h3 data-v-9792f17c=""
																						 className="panel-header-title">{t('security')}</h3></div>
									<div data-v-9792f17c="" className="panel-body">
										<div data-v-9792f17c="" className="row mb-5">
											<div data-v-9792f17c="" className="col-md-3 col-xs-12">
												<div data-v-9792f17c="" className="form-group"><span
													data-v-9792f17c="" className="labelInput">{t('current_password')}</span>
													<input
														data-v-9792f17c="" onChange={this.onChangeInput('current_password')}
														value={current_password || ''} type="password"
														className="form-control mb-2 winput"/>
												</div>
											</div>
											<div data-v-9792f17c="" className="col-md-3 col-xs-12">
												<div data-v-9792f17c="" className="form-group"><span
													data-v-9792f17c="" className="labelInput">{t('new_password')}</span>
													<input
														data-v-9792f17c="" onChange={this.onChangeInput('new_password')}
														value={new_password || ''} type="password"
														className="form-control mb-2 winput"/>
												</div>
											</div>
											<div data-v-9792f17c="" className="col-md-4 col-xs-12">
												<button data-v-9792f17c="" style={{ marginTop: '25px', height: '43px', width: '100%' }} type="button"  onClick={this.onSubmitPassword}
														disabled={!(current_password && new_password && new_password.length > 5)}
														className="button wbtn btn-radius btn-large btn-border color-white btn-secondary">{t('update')} {t('password')}
												</button>
											</div>
										</div>

										<div data-v-9792f17c="" className="row changeAuthWrap mb-5">
											<div data-v-9792f17c="" className="col-8 col-md-4 mb-2 mb-lg-0">
												<div data-v-9792f17c=""
													 className="d-flex align-items-center h-100 margin5 font-14 color-white">{t('code_2fa')}:
												</div>
											</div>
											<div data-v-9792f17c="" className="col-md-4 d-sm-block">
												<div data-v-9792f17c="" className="d-flex align-items-center h-100"><p
													data-v-9792f17c=""
													className="colorGray font-14 d-flex flex-column justify-conntent-center mb-0">
													<span data-v-9792f17c="">{t('require_2fa_message')}.</span>
												</p></div>
											</div>
											<div data-v-9792f17c="" className="col-4 col-md-2 changeAuth">
												<label data-v-425bef87="" data-v-9792f17c="" className="toogle mb-0" onClick={this.onChangeTwoFa}>
													<div data-v-425bef87="" className={setClassNameCondition(is_two_fa, "on", 'off', 'switch-core')}>
														<span data-v-425bef87="" className={setClassNameCondition(is_two_fa, 'left', 'right', 'switch-label')}>{is_two_fa ? 'On' : 'Off'}</span>
														<div data-v-425bef87="" className={setClassNameCondition(is_two_fa, 'right', 'left', 'switch-button')} />
													</div>
												</label>
											</div>
											<div data-v-9792f17c="" className="col-12"/>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div data-v-3bbb76d6="" data-v-9792f17c="" tabIndex="-1" role="dialog" className="modal fade modalEnable show" style={{ display: is_show_modal_auth ? 'block' : 'none' }}>
							<div data-v-3bbb76d6="" className="mask" />
							<div data-v-3bbb76d6="" className="modal-dialog simple modal-sm modal-2fa-custom">
								<form data-v-3bbb76d6=""  style={{ border: '1px solid #4b4a79' }}>
									<div data-v-3bbb76d6="" className="modal-content">
										<button data-v-3bbb76d6="" type="button" onClick={this.onShowAuthModal(false)} aria-label="Close" className="close">
											<img data-v-3bbb76d6=""
												 src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAzMiAzMiI+CiAgICA8ZyBzdHJva2UtbGluZWNhcD0ic3F1YXJlIiBzdHJva2UtbGluZWpvaW49Im1pdGVyIiBzdHJva2Utd2lkdGg9IjIiIGZpbGw9IiNmZmZmZmYiIHN0cm9rZT0iI2ZmZmZmZiIgY2xhc3M9Im5jLWljb24td3JhcHBlciI+CiAgICAgICAgPGcgY2xhc3M9Im5jLWludGVyYWN0X21lbnUtY2xvc2UtMi1vLTMyIj4KICAgICAgICAgICAgPHBhdGggZmlsbD0ibm9uZSIgc3Ryb2tlPSIjZmZmZmZmIiBzdHJva2UtbWl0ZXJsaW1pdD0iMTAiIGQ9Ik0yIDZoMjgiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDAgMTAuMDApIHJvdGF0ZSg0NS4wMCAxNiA2KSIgLz4KICAgICAgICAgICAgPHBhdGggZGF0YS1jb2xvcj0iY29sb3ItMiIgZmlsbD0ibm9uZSIgc3Ryb2tlLW1pdGVybGltaXQ9IjEwIiBkPSJNMiAxNmgyOCIgb3BhY2l0eT0iMCIgLz4KICAgICAgICAgICAgPHBhdGggZmlsbD0ibm9uZSIgc3Ryb2tlPSIjZmZmZmZmIiBzdHJva2UtbWl0ZXJsaW1pdD0iMTAiIGQ9Ik0yIDI2aDI4IiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgwIC0xMCkgcm90YXRlKC00NSAxNiAyNikiIC8+CiAgICAgICAgPC9nPgogICAgPC9nPgo8L3N2Zz4="
												 alt="" /></button>
										<div data-v-3bbb76d6="" className="modal-body">
											<div data-v-3bbb76d6="" className="subtitle mb-2"><h3 data-v-3bbb76d6=""
																								  className="color-white font-weight-bold text-center mb-0">{t('google_authentication')}</h3></div>
											<div data-v-3bbb76d6="" className="row mb-5 mb-sm-4">
												<div data-v-3bbb76d6="" className="col-12 text-center"><p
													data-v-3bbb76d6="" className="desc mb-0"><small data-v-3bbb76d6=""
																									className="description"><span
													data-v-3bbb76d6="" className="noted colorRed">
                                        {t('google_authentication_message')}
                                    </span></small></p></div>
											</div>
											<div data-v-3bbb76d6="" className="boxContent g_auth">
												<div data-v-3bbb76d6="" className="row h-100">
													<div data-v-3bbb76d6="" className="col-12 d-flex flex-column">
														<div data-v-3bbb76d6=""
															 className="text-center qr-code-wrap mb-5 mb-sm-4">
															<div data-v-3de7c115="" data-v-3bbb76d6="" style={{ width: '150px', height: '150px' }} className="qr-code-container qr-200 mx-auto">
																<div dangerouslySetInnerHTML={{__html: qr_code}} />
															</div>
														</div>
														<div data-v-3bbb76d6="" className="row backupkey">
															<div data-v-3bbb76d6=""
																 className="col-md-3 label color-white-50">{t('recovery_code')}:
															</div>
															<div data-v-3bbb76d6=""
																 className="col-md-9 key pl-0 pr-lg-3 pr-0">
																<div data-v-3bbb76d6="" className="grouplabel">
																	<div data-v-3bbb76d6=""
																		 className="input-group transparent"><input
																		data-v-3bbb76d6="" type="text"
																		readOnly="readonly"
																		value={secret || ''}
																		className="form-control pl-3 pl-sm-0" />
																		<div data-v-3bbb76d6="" className="input-group-append"><a
																			data-v-3bbb76d6="" href="#"
																			onClick={this.onCopySecret}
																			className="input-group-addon">
																			<svg data-v-3bbb76d6=""
																				 xmlns="http://www.w3.org/2000/svg"
																				 viewBox="0 0 14 16">
																				<g data-v-3bbb76d6=""
																				   id="single-copy-04"
																				   transform="translate(-2)">
																					<g data-v-3bbb76d6="" id="Group_190"
																					   data-name="Group 190">
																						<rect data-v-3bbb76d6=""
																							  id="Rectangle_502"
																							  data-name="Rectangle 502"
																							  width="10" height="12"
																							  transform="translate(2.5 3.5)"
																							  className="icon-copy"/>
																						<path data-v-3bbb76d6=""
																							  id="Path_90"
																							  data-name="Path 90"
																							  d="M4.5.5h11v13"
																							  className="icon-copy"/>
																						<line data-v-3bbb76d6=""
																							  id="Line_187"
																							  data-name="Line 187"
																							  x2="4"
																							  transform="translate(5.5 6.5)"
																							  className="icon-copy"/>
																						<line data-v-3bbb76d6=""
																							  id="Line_188"
																							  data-name="Line 188"
																							  x2="4"
																							  transform="translate(5.5 9.5)"
																							  className="icon-copy"/>
																						<line data-v-3bbb76d6=""
																							  id="Line_189"
																							  data-name="Line 189"
																							  x2="4"
																							  transform="translate(5.5 12.5)"
																							  className="icon-copy"/>
																					</g>
																				</g>
																			</svg>
																		</a>
																		</div>
																	</div>
																</div>
															</div>
														</div>
														<div data-v-3bbb76d6="" className="form-group"><label
															data-v-3bbb76d6="" className="color-white-50">{t('password')}</label> <input data-v-3bbb76d6="" type="password"
																				value={auth_password}
																				onChange={this.onChangeInput('auth_password')}
																				className="form-control"/></div>
														<div data-v-3bbb76d6=""
															 className="form-group verification-code"><label
															data-v-3bbb76d6="" className="color-white-50">{t('email_code')}</label>
															<div data-v-3bbb76d6="" className="input-group mb-3">
																<div data-v-3bbb76d6="" className="position-relative">
																	<input data-v-3bbb76d6="" type="text"
																		   placeholder={t('email_code')}
																		   value={auth_email_code}
																		   onChange={this.onChangeInput('auth_email_code')}
																		   className="form-control"/> <span
																		data-v-3bbb76d6=""
																		onClick={this.onClickPaste('auth_email_code')}
																		className="paste">{t('paste')}</span></div>
																<button data-v-3bbb76d6="" type="button"
																		onClick={this.onSendOTPEmail}
																		className="btn btn-primary">
																	{t('send_code')}
																</button>
															</div>
														</div>
														<div data-v-3bbb76d6="" className="form-group"><label
															data-v-3bbb76d6="" className="color-white-50">{t('code_2fa')}</label>
															<div data-v-3bbb76d6="" className="position-relative"><input
																data-v-3bbb76d6="" type="number"
																value={auth_two_fa_code}
																onChange={this.onChangeInput('auth_two_fa_code')}
																className="form-control" /> <span data-v-3bbb76d6="" onClick={this.onClickPaste('auth_two_fa_code')}
																								className="paste">{t('paste')}</span>
															</div>
														</div>
														<div data-v-3bbb76d6="" className="listTools mt-auto">
															<div data-v-3bbb76d6="" className="row">
																<div data-v-3bbb76d6=""
																	 className="col-md-6 offset-md-3">
																	<button data-v-3bbb76d6="" disabled={!is_button_enable} type="button" onClick={this.onEnableTwoFa} className="btn btn-primary btn-lg btn-block">{t('enable')}</button>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</form>
							</div>
						</div>

						<div data-v-3bbb76d6="" data-v-9792f17c="" tabIndex="-1" role="dialog" className="modal fade modalEnable show" style={{ display: is_show_modal_disable_two_fa ? 'block' : 'none' }}>
							<div data-v-3bbb76d6="" className="mask" />
							<div data-v-3bbb76d6="" className="modal-dialog simple modal-sm modal-2fa-custom">
								<form data-v-3bbb76d6="" style={{ border: '1px solid #4b4a79' }}>
									<div data-v-3bbb76d6="" className="modal-content">
										<button data-v-3bbb76d6="" type="button" onClick={this.onShowDisableTwoFa(false)} aria-label="Close" className="close">
											<img data-v-3bbb76d6=""
												 src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAzMiAzMiI+CiAgICA8ZyBzdHJva2UtbGluZWNhcD0ic3F1YXJlIiBzdHJva2UtbGluZWpvaW49Im1pdGVyIiBzdHJva2Utd2lkdGg9IjIiIGZpbGw9IiNmZmZmZmYiIHN0cm9rZT0iI2ZmZmZmZiIgY2xhc3M9Im5jLWljb24td3JhcHBlciI+CiAgICAgICAgPGcgY2xhc3M9Im5jLWludGVyYWN0X21lbnUtY2xvc2UtMi1vLTMyIj4KICAgICAgICAgICAgPHBhdGggZmlsbD0ibm9uZSIgc3Ryb2tlPSIjZmZmZmZmIiBzdHJva2UtbWl0ZXJsaW1pdD0iMTAiIGQ9Ik0yIDZoMjgiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDAgMTAuMDApIHJvdGF0ZSg0NS4wMCAxNiA2KSIgLz4KICAgICAgICAgICAgPHBhdGggZGF0YS1jb2xvcj0iY29sb3ItMiIgZmlsbD0ibm9uZSIgc3Ryb2tlLW1pdGVybGltaXQ9IjEwIiBkPSJNMiAxNmgyOCIgb3BhY2l0eT0iMCIgLz4KICAgICAgICAgICAgPHBhdGggZmlsbD0ibm9uZSIgc3Ryb2tlPSIjZmZmZmZmIiBzdHJva2UtbWl0ZXJsaW1pdD0iMTAiIGQ9Ik0yIDI2aDI4IiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgwIC0xMCkgcm90YXRlKC00NSAxNiAyNikiIC8+CiAgICAgICAgPC9nPgogICAgPC9nPgo8L3N2Zz4="
												 alt="" /></button>
										<div data-v-3bbb76d6="" className="modal-body">
											<div data-v-3bbb76d6="" className="subtitle mb-2"><h3 data-v-3bbb76d6=""
																								  className="color-white font-weight-bold text-center mb-0">{t('disable_google_auth')}</h3></div>
											<div data-v-3bbb76d6="" className="boxContent g_auth">
												<div data-v-3bbb76d6="" className="row h-100">
													<div data-v-3bbb76d6="" className="col-12 d-flex flex-column">
														<div data-v-3bbb76d6="" className="form-group"><label
															data-v-3bbb76d6="" className="color-white-50">{t('password')}</label> <input data-v-3bbb76d6="" type="password"
																				value={auth_password}
																				onChange={this.onChangeInput('auth_password')}
																				className="form-control"/></div>
														<div data-v-3bbb76d6=""
															 className="form-group verification-code"><label
															data-v-3bbb76d6="" className="color-white-50">{t('email_code')}</label>
															<div data-v-3bbb76d6="" className="input-group mb-3">
																<div data-v-3bbb76d6="" className="position-relative">
																	<input data-v-3bbb76d6="" type="text"
																		   placeholder={t('email_code')}
																		   value={auth_email_code}
																		   onChange={this.onChangeInput('auth_email_code')}
																		   className="form-control"/> <span
																	data-v-3bbb76d6=""
																	onClick={this.onClickPaste('auth_email_code')}
																	className="paste">{t('paste')}</span></div>
																<button data-v-3bbb76d6="" type="button"
																		onClick={this.onSendOTPEmail}
																		className="btn btn-primary">
																	{t('send_code')}
																</button>
															</div>
														</div>
														<div data-v-3bbb76d6="" className="form-group"><label
															data-v-3bbb76d6="" className="color-white-50">{t('code_2fa')}</label>
															<div data-v-3bbb76d6="" className="position-relative"><input
																data-v-3bbb76d6="" type="number"
																value={auth_two_fa_code}
																onChange={this.onChangeInput('auth_two_fa_code')}
																className="form-control" /> <span data-v-3bbb76d6="" onClick={this.onClickPaste('auth_two_fa_code')}
																								  className="paste">{t('send_code')}</span>
															</div>
														</div>
														<div data-v-3bbb76d6="" className="listTools mt-auto">
															<div data-v-3bbb76d6="" className="row">
																<div data-v-3bbb76d6=""
																	 className="col-md-6 offset-md-3">
																	<button data-v-3bbb76d6="" disabled={!is_button_enable} type="button" onClick={this.onDisableTwoFa} className="btn btn-primary btn-lg btn-block">{t('enable')}</button>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</form>
							</div>
						</div>

						<KYCModal updateMemberInfo={this.onUpdateMemberInfo} member={{ first_name, last_name }} showModal={this.onShowVerifyModal} is_show_modal_kyc={is_show_modal_kyc} />
					</div>
				</div>
			</div>
		);
	}
}

function mapStateToProps(state) {
	return {
		memberReducer: state.memberReducer,
		appReducer: state.appReducer
	};
}

export default withTranslation()(connect(mapStateToProps, {
	fetchingUser,
	fetchingUserSuccess
})(withRouter(Profile)));

