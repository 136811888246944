import {Component} from "react";
import {connect} from "react-redux";
import {fetchingTransactionAction, updateTransactionAction} from "../../../redux/actions";
import {betCondition, setClassNameCondition} from "../../helper";
import constants from "../../constants";
import {withTranslation} from "react-i18next";
const TRANSACTION_STATUS = constants.TRANSACTION_STATUS;

class TransactionBox extends Component{
	constructor(props) {
		super(props);
	}

	onChangeSelectedTab = (tab) => (e) => {
		e.preventDefault();
		this.props.updateTransactionAction({selected_tab: tab});
		this.props.fetchingTransactionAction();
	}

	render() {
		let { selected_tab, transactions, total_bet_open } = this.props.transactionReducer;
		let {t} = this.props;
		return <div data-v-2bbc4f3d="" data-v-5b2de64c="" id="binaryTransaction">
				<ul data-v-2bbc4f3d="" id="myTab" role="tablist" className="nav nav-tabs">
					<li data-v-2bbc4f3d="" className="nav-item">
						<a data-v-2bbc4f3d=""
						   onClick={this.onChangeSelectedTab(TRANSACTION_STATUS.PENDING)}
						   data-toggle="tab" href="#"
						   role="tab" aria-controls={TRANSACTION_STATUS.PENDING}
						   aria-selected={selected_tab === TRANSACTION_STATUS.PENDING}
						   className={setClassNameCondition(selected_tab === TRANSACTION_STATUS.PENDING, 'active show', '', 'nav-link')}>
							<span data-v-2bbc4f3d="" className="text-uppercase">{t('open')}</span>
							<span data-v-2bbc4f3d=""
								  className="totalCount text-uppercase"
								  style={{display: total_bet_open > 0 ? 'initial' : 'none'}}>{total_bet_open}</span></a>
					</li>
					<li data-v-2bbc4f3d="" className="nav-item">
						<a data-v-2bbc4f3d=""
						   onClick={this.onChangeSelectedTab(TRANSACTION_STATUS.FINISH)}
						   data-toggle="tab" href="#"
						   role="tab" aria-controls={TRANSACTION_STATUS.FINISH}
						   aria-selected={selected_tab === TRANSACTION_STATUS.FINISH}
						   className={setClassNameCondition(selected_tab === TRANSACTION_STATUS.FINISH, 'active show', '', 'nav-link')}>
							<span data-v-2bbc4f3d="" className="text-uppercase">{t('close')}</span>
						</a>
					</li>
				</ul>
				<section data-v-2bbc4f3d=""
						 className="ps-container scroll-area ps ps--theme_default ps--active-y"
						 data-ps-id="1db45f1e-7dce-eb5b-4e53-83ab996f130d">
					<div data-v-2bbc4f3d="" id="myTabContent" className="tab-content h-100">
						<div data-v-5c253b3b="" data-v-2bbc4f3d="" style={{overflow: 'auto'}}
							 className="d-flex flex-column justify-content-between h-100">
							<div data-v-5c253b3b="">
								<div data-v-5c253b3b="">
									<p data-v-5c253b3b="" className="font-16 color-gray mt-3"/>
									{transactions.map((item, index) => {
										return (
											<div data-v-5c253b3b=""
												 key={index}
												 className="transactionBox font-14 mb-2">
												<div data-v-5c253b3b=""
													 className="d-flex justify-content-between mb-2"><span
													data-v-5c253b3b=""
													className="font-14 font-weight-700">BTC/USD <span
													data-v-5c253b3b=""
													className="accType font-10">{t(item['point_type'])}</span></span>
													<span
														data-v-5c253b3b=""
														className="icon iconBitcoin"/>
												</div>
												<div data-v-5c253b3b=""
													 className="d-flex justify-content-between mb-2"><span
													data-v-5c253b3b=""
													className="trendGroup d-flex align-items-center"><span
													data-v-5c253b3b=""
													className={setClassNameCondition(item['bet_condition'] === 'up', 'trendUp', 'trendDown', 'trendIcon mr-2')}/> <span
													data-v-5c253b3b=""
													className="text-uppercase">{t(item.bet_condition)}</span></span>
													<span
														data-v-5c253b3b=""
														className="font-12m">${item.bet_value}</span>
												</div>
												<div data-v-5c253b3b=""
													 className="d-flex justify-content-between"><span
													data-v-5c253b3b=""
													className="font-12 time">{item['created_at']}</span>
													<span data-v-5c253b3b=""
														  className={setClassNameCondition(item.value > 0, 'winUp', 'winDown', 'totalWin font-weight-700 font-12m')}><span
														data-v-5c253b3b=""
														className="text-uppercase"/>${item.value > 0 ? '+' + item.value : 0}</span>
												</div>
											</div>
										)
									})}
								</div>
							</div>
						</div>
					</div>
				</section>
			</div>
	}
}


function mapStateToProps(state) {
	return {
		transactionReducer: state.transactionReducer
	};
}

export default withTranslation()(connect(mapStateToProps, {
	fetchingTransactionAction,
	updateTransactionAction
})(TransactionBox));
