import { Component } from 'react'
import moment from "moment";
import api from "../../../../lib/api";
import {formatNumber} from "../../../../lib/helper";
import { withTranslation } from "react-i18next";

class Info extends Component{
	constructor(props) {
		super(props);
		this.state = {
			rewards: [],
			description: ''
		};
	}

	componentDidMount() {
		this.loadChallengeDetail();
	}

	loadChallengeDetail = () => {
		let { detail } = this.props;
		api.getChallengeInfo(detail).then(({ data }) => {
			this.setState({ description: (data.description || '').replace(/\r?\n/g, '<br/>'), rewards: data.rewards });
		});
	}

	render() {
		let { detail, t } = this.props;
		let { start, end } = detail;
		let { description, rewards } = this.state;
		return (
			<div data-v-2f4ce6a2="">
				<div data-v-2f4ce6a2="" className="row">
					<div data-v-2f4ce6a2="" className="col-lg-6">
						<div data-v-2f4ce6a2="" className="d-flex infoBox">
							<div data-v-2f4ce6a2="" className="leftContent"><p data-v-2f4ce6a2="">Thời gian bắt đầu</p>
								<p data-v-2f4ce6a2="" className="mb-0">Thời gian kết thúc</p></div>
							<div data-v-2f4ce6a2="" className="ml-auto"><p data-v-2f4ce6a2="">{moment(start).format('DD/MM/YYYY HH:mm:ss')}</p> <p
								data-v-2f4ce6a2="" className="mb-0">{moment(end).format('DD/MM/YYYY HH:mm:ss')}</p></div>
						</div>
					</div>
				</div>
				<div data-v-2f4ce6a2="">
					<div dangerouslySetInnerHTML={{__html: description}} />
					<p className='mt-3'>*** Thứ hạng và phần thưởng</p>
					<div>
						{(rewards || []).map(({ rank, reward }) => {
							return <p>Hạng {rank}: ${formatNumber(reward)}</p>
						})}
					</div>
				</div>
			</div>
		)
	}
}

export default withTranslation()(Info);
