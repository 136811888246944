import '../../assets/css/affiliate.css';

import { Component } from 'react';

import {connect} from "react-redux";
import {fetchingUser, fetchingUserSuccess} from "../../redux/actions";
import {withRouter} from "react-router-dom";
import {formatNumber, getUrl, setClassNameCondition, showNotification, isDesktop} from "../../lib/helper";
import api from "../../lib/api";
import DataTable from "react-data-table-component";
import moment from "moment";
import DateRangePicker from "../../lib/custom/DateRangePicker";
import Network from "./network";
import { withTranslation } from 'react-i18next';

class Affiliate extends Component {
	constructor(props) {
		super(props);
		this.state = {
			is_show_buy: false,
			agree_vip: false,
			selected_tab: 'guide',
			trade_commission: 0,
			vip_commission: 0,

			commission: {
				page: 1,
				per_page: 10,
				data: [],
				total: 0,
				sort_selector: 'created_at',
				sort_direction: 'desc',
				filter_from: moment(),
				filter_to: moment(),
			}
		};
		this.columnsCommission = [
			{
				name: 'Mã giao dịch',
				selector: 'code',
				sortable: false,
			},
			{
				name: 'Thời gian',
				selector: 'created_at',
				sortable: true,
			},
			{
				name: 'Loại hoa hồng',
				selector: 'transaction_type_label',
				data: 'transaction_type',
				sortable: false,
				format: (row, index) => (<span className={'transaction_type '+row.transaction_type}>{row.transaction_type_label}</span>),
				allowOverflow: true
			},
			{
				name: 'Đến từ',
				selector: 'commission_ref_name',
				data: 'commission_member_id',
				sortable: true,
				format: (row, index) => (<span><b>(F{row.commission_level})</b> {row.commission_ref_name}</span>),
				allowOverflow: true
			},
			{
				name: 'Giá trị ($)',
				selector: 'value',
				sortable: true,
				right: true,
				format: (row, index) => (<span style={{ color: row.value < 0 ? 'red' : 'black' }}>{formatNumber(row.value)}</span>),
			},
		];
	}

	onClickModel = (type) => () => {
		this.setState({ [type]: !this.state[type] })
	}

	onCopy = () => {
		let { username } = this.props.memberReducer;
		navigator.clipboard.writeText(getUrl('register?r=' + username))
			.then(() => {
				showNotification({ type: 'success', message: 'Đã sao chép vào bộ nhớ' });
			})
			.catch(e => {
				showNotification({ type: 'danger', message: e.message });
			})
	}

	onBuyVip = () => {
		this.onClickModel('is_show_buy')();
		api.postBuyVip().then(({ message, success, data }) => {
			showNotification({ message: message, type: success ? 'success' : 'danger' })
			if(success){
				this.props.fetchingUser();
			}
		})
	}

	onChangeAgreeVip = () => {
		this.setState({ agree_vip: !this.state.agree_vip })
	}

	onSelectTab = (tab) => (e) => {
		e && e.preventDefault();
		this.setState({ selected_tab: tab }, () => {
			if(tab === 'commission'){
				api.getCommission().then(({ data, success }) => {
					success && Object.keys(data).forEach(key => this.setState({ [key]: data[key] }))
				});
				this.loadTransaction();
			}
		});
	}

	onSortTransaction = (column, sortDirection) => {
		let { commission } = this.state;
		this.setState({ commission: { ...commission, sort_selector: column.data || column.selector, sort_direction: sortDirection } }, this.loadTransaction)
	}

	loadTransaction = () => {
		let { commission } = this.state;
		let { page, per_page, sort_selector, sort_direction, filter_from, filter_to } = commission;
		api.getTransaction({ limit: per_page, page, sort_selector, sort_direction,
			filter_from: filter_from.format('YYYY-MM-DD'),
			filter_to: filter_to.format('YYYY-MM-DD'),
			transaction_type: 'ref'
		}).then(({ data, success }) => {
			if(success){
				let { commission } = this.state;
				this.setState({ commission: { ...commission, data: data.data, total: data.total } });
			}
		})
	}

	handlePageChangeCommission = (page) => {
		let { commission } = this.state;
		this.setState({ commission: { ...commission, page } }, this.loadTransaction)
	}

	handlePerRowsChangeCommission = (per_page) => {
		let { commission } = this.state;
		this.setState({ commission: { ...commission, per_page } }, this.loadTransaction)
	}

	handleChangeDateRange = (start, end) => {
		let { commission } = this.state;
		this.setState({ commission: { ...commission, filter_from: start, filter_to: end } }, this.loadTransaction)
	}

	render() {
		let { vip_price, trade_percent_commission, vip_percent_commission, window_width, asset_affiliate_network_banner } = this.props.appReducer;
		let { is_show_buy, agree_vip, selected_tab, trade_commission, vip_commission, commission } = this.state;
		let { user_mode, username } = this.props.memberReducer;
		let {t} = this.props;
		return (
			<div data-v-b0e45e8c="" id="mainContent">
				<div data-v-b0e45e8c="" className="boxContent">
					<div className='community'>
						<div data-v-46bc7c21="" data-v-b0e45e8c="" className="page">
							<div data-v-5884b68b="" data-v-46bc7c21="" className="backgroundDark">
								<div data-v-5884b68b="" className="container">
									<ul data-v-5884b68b="" id="pills-tab" role="tablist" className="nav nav-pills menuHeaderLink">
										<li data-v-5884b68b="" id="community" className="nav-item">
											<a data-v-5884b68b="" href="#" onClick={this.onSelectTab('guide')} className={setClassNameCondition(selected_tab === 'guide', 'router-link-exact-active router-link-active', '', 'nav-link')}>{t('guide')}</a>
										</li>
										<li data-v-5884b68b="" id="portfolio" className="nav-item">
											<a data-v-5884b68b="" href="#" onClick={this.onSelectTab('commission')} className={setClassNameCondition(selected_tab === 'commission', 'router-link-exact-active router-link-active', '', 'nav-link')}>{t('commission')}</a>
										</li>
										<li data-v-5884b68b="" id="expertArea" className="nav-item">
											<a data-v-5884b68b="" href="#"  onClick={this.onSelectTab('network')} className={setClassNameCondition(selected_tab === 'network', 'router-link-exact-active router-link-active', '', 'nav-link')}>{t('network')}</a>
										</li>
									</ul>
								</div>
							</div>
							{selected_tab ==='guide' && <div data-v-22dfce66="" data-v-b0e45e8c="" className="bodyContent affiliatePage">
								<div data-v-22dfce66="">
									<div data-v-22dfce66="">
										<div data-v-22dfce66="" className="banner">
											<div data-v-22dfce66="" className="imgBanner">
												<div data-v-22dfce66="" className="backgroundImg imgLight" />
											</div>
											<div data-v-22dfce66="" className="content">
												<div data-v-22dfce66="" className="container h-100">
													<div data-v-22dfce66="" className="row h-100 align-items-center bbh">
														{user_mode !== 'unlimited' && <div data-v-22dfce66="" className="col-lg-6 col-6-landscape"><p
															data-v-22dfce66=""
															className="font-30 font-18m font-weight-700 color-white line-height-normal mb-4">{t('become_vip_member')}</p>
															<button data-v-22dfce66=""
																	type="button"
																	onClick={this.onClickModel('is_show_buy')}
																	className="btn button wbtn btn-green btn-large">{t('buy_now')} ${ formatNumber(vip_price) }</button>
														</div>}
														<div data-v-22dfce66=""
															 className={setClassNameCondition(user_mode === 'unlimited', 'col-lg-12', 'col-lg-6', 'col-6-landscape affiliateInfoMobile')}>
															<div data-v-22dfce66="" className="affiliateInfo">
																<div data-v-d201240a="" data-v-22dfce66=""
																	 className="register-link"> <label
																	data-v-d201240a="">{t('share_to_profit')}</label>
																	<div data-v-d201240a="" className="groupButton d-flex">
																		<div data-v-d201240a="" className="leftControl"><input
																			data-v-d201240a="" type="text" readOnly="readonly"
																			value={getUrl('register?r=' + username)}
																			className="form-control w-100 font-14" /></div>
																		<div data-v-d201240a="" className="rightControl">
																			<button data-v-d201240a="" onClick={this.onCopy} className="btn"><img
																				data-v-d201240a=""
																				src="data:image/svg+xml;base64,PHN2ZyBpZD0iaWNfY29udGVudF9jb3B5XzQ4cHgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEzLjE0NiIgaGVpZ2h0PSIxNS4yMjEiIHZpZXdCb3g9IjAgMCAxMy4xNDYgMTUuMjIxIj4KICA8cGF0aCBpZD0iUGF0aF8zNjIyNSIgZGF0YS1uYW1lPSJQYXRoIDM2MjI1IiBkPSJNMTMuNjg2LDJoLTguM0ExLjM4MywxLjM4MywwLDAsMCw0LDMuMzg0VjEzLjA3SDUuMzg0VjMuMzg0aDguM1ptMi4wNzYsMi43NjhIOC4xNTFBMS4zODMsMS4zODMsMCwwLDAsNi43NjgsNi4xNTF2OS42ODZhMS4zODMsMS4zODMsMCwwLDAsMS4zODQsMS4zODRoNy42MTFhMS4zODMsMS4zODMsMCwwLDAsMS4zODQtMS4zODRWNi4xNTFBMS4zODMsMS4zODMsMCwwLDAsMTUuNzYyLDQuNzY4Wm0wLDExLjA3SDguMTUxVjYuMTUxaDcuNjExWiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTQgLTIpIiBmaWxsPSIjZmZmIi8+Cjwvc3ZnPgo=" />
																			</button>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
										<div data-v-22dfce66="" className="subBanner d-flex align-items-center">
											<div data-v-14142db8="" data-v-22dfce66="" className="container ">
												<div data-v-14142db8="" className="row align-items--center">
													<div data-v-14142db8="" className="col">
														<div data-v-14142db8=""
															 className="d-flex boxContent h-100 align-items-center">
															<div data-v-14142db8="" className="d-flex align-items-center">
																<div data-v-14142db8="">
																	<div data-v-14142db8="" className="icon iconInvite" />
																</div>
																<div data-v-14142db8="">
																	<div data-v-14142db8="" className="textContent"><p
																		data-v-14142db8="" className="mb-1">{t('invite_friend')}</p> <p
																		data-v-14142db8="" className="color-white-50 mb-0">{t('invite_friend_des')}</p></div>
																</div>
															</div>
														</div>
													</div>
													<div data-v-14142db8="" className="borderSubBanner" />
													<div data-v-14142db8="" className="col">
														<div data-v-14142db8=""
															 className="d-flex boxContent h-100 align-items-center">
															<div data-v-14142db8="" className="d-flex align-items-center">
																<div data-v-14142db8="">
																	<div data-v-14142db8="" className="icon iconSign" />
																</div>
																<div data-v-14142db8="">
																	<div data-v-14142db8="" className="textContent"><p
																		data-v-14142db8="" className="mb-1">{t('friend_register')}</p> <p
																		data-v-14142db8="" className="color-white-50 mb-0">{t('friend_register_des')}</p></div>
																</div>
															</div>
														</div>
													</div>
													<div data-v-14142db8="" className="borderSubBanner" />
													<div data-v-14142db8="" className="col">
														<div data-v-14142db8=""
															 className="d-flex boxContent h-100 align-items-center">
															<div data-v-14142db8="" className="d-flex align-items-center">
																<div data-v-14142db8="">
																	<div data-v-14142db8=""
																		 className="icon iconCommission" />
																</div>
																<div data-v-14142db8="">
																	<div data-v-14142db8="" className="textContent"><p
																		data-v-14142db8="" className="mb-1">{t('get_commission')}</p> <p data-v-14142db8=""
																						 className="color-white-50 mb-0">{t('get_commission_des')}</p></div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div data-v-36903699="" data-v-22dfce66="" className="authPopup">
										<div data-v-36903699="" tabIndex="-1" role="dialog" aria-hidden="true"
											 style={{ display: is_show_buy ? 'block' : 'none' }}
											 className={setClassNameCondition(is_show_buy, 'show','', 'modal fade modalBuyNow')}>
											<div data-v-36903699="" className="modal-dialog modal-auth">
												<div data-v-36903699="" onClick={this.onClickModel('is_show_buy')} className="close-page">
													<span data-v-36903699="" className="pe pe-7s-close" />
												</div>
												<div data-v-36903699="" className="modal-content">
													<div data-v-36903699="" className="contentWrapper">
														<div data-v-36903699="" className="header"><span data-v-36903699=""
																										 className="font-20 font-weight-700 text-capitalize">{t('accept_vip_member')}</span>
														</div>
														<div data-v-36903699="" className="body">
															<div data-v-36903699="" className="imgShake mb-3" />
															<div data-v-36903699="" className="textContent mb-3">{t('need_continue', { price: formatNumber(vip_price) })}
															</div>
															<div data-v-36903699=""
																 className="checkbox d-flex justify-content-center"><input
																onChange={this.onChangeAgreeVip}
																data-v-36903699="" type="checkbox" checked={agree_vip} id="customControlInline"
																className="checkboxInput" />
																<span data-v-36903699="" htmlFor="customControlInline">{t('accept_and_agree')}</span></div>
															<button data-v-36903699="" disabled={!agree_vip}
																	onClick={this.onBuyVip}
																	className="btn button wbtn btn-large btn-radius w-100 font-weight-700">{t('confirm')}</button>
															<p data-v-36903699="" className="textError"/></div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>}
							{ selected_tab === 'commission' && <div data-v-10df954e="" style={{ paddingBottom: '40px' }} data-v-46bc7c21="" className="portfolioStats">
								<div data-v-10df954e="" className="desktop container">
									<div data-v-10df954e="" className="d-flex">
										<img data-v-10df954e="" alt='' src="data:image/svg+xml;base64,PHN2ZyBpZD0iY2hhcnQtYmFyLTMyIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMC42NzgiIGhlaWdodD0iMjAuNjc4IiB2aWV3Qm94PSIwIDAgMjAuNjc4IDIwLjY3OCI+CiAgPHBhdGggaWQ9IlBhdGhfMzQ1NTEiIGRhdGEtbmFtZT0iUGF0aCAzNDU1MSIgZD0iTTkuODc3LDBINy4yOTJBMS4yMjEsMS4yMjEsMCwwLDAsNiwxLjI5MlYxOS4zODVhMS4yMjEsMS4yMjEsMCwwLDAsMS4yOTIsMS4yOTJIOS44NzdhMS4yMjEsMS4yMjEsMCwwLDAsMS4yOTItMS4yOTJWMS4yOTJBMS4yMjEsMS4yMjEsMCwwLDAsOS44NzcsMFoiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDEuNzU0KSIgZmlsbD0iI2ZmZiIvPgogIDxwYXRoIGlkPSJQYXRoXzM0NTUyIiBkYXRhLW5hbWU9IlBhdGggMzQ1NTIiIGQ9Ik0zLjg3NywxMEgxLjI5MkExLjIyMSwxLjIyMSwwLDAsMCwwLDExLjI5MnY1LjE2OWExLjIyMSwxLjIyMSwwLDAsMCwxLjI5MiwxLjI5MkgzLjg3N2ExLjIyMSwxLjIyMSwwLDAsMCwxLjI5Mi0xLjI5MlYxMS4yOTJBMS4yMjEsMS4yMjEsMCwwLDAsMy44NzcsMTBaIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgwIDIuOTI0KSIgZmlsbD0iIzIwNzdmYyIvPgogIDxwYXRoIGlkPSJQYXRoXzM0NTUzIiBkYXRhLW5hbWU9IlBhdGggMzQ1NTMiIGQ9Ik0xNS44NzcsNUgxMy4yOTJBMS4yMjEsMS4yMjEsMCwwLDAsMTIsNi4yOTJWMTcuOTI0YTEuMjIxLDEuMjIxLDAsMCwwLDEuMjkyLDEuMjkyaDIuNTg1YTEuMjIxLDEuMjIxLDAsMCwwLDEuMjkyLTEuMjkyVjYuMjkyQTEuMjIxLDEuMjIxLDAsMCwwLDE1Ljg3Nyw1WiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMy41MDggMS40NjIpIiBmaWxsPSIjMjA3N2ZjIi8+Cjwvc3ZnPgo=" />
										<h1 data-v-10df954e="" className="title ml-3">{t('commission_statistics')}</h1>
									</div>
									{isDesktop() ?
										<div data-v-10df954e="" className="mt-5 container-fluid">
											<div data-v-10df954e="" className="row">
												<div data-v-10df954e="" className="col-4 pr-0">
													<div data-v-10df954e="" className="boxContent">
														<p data-v-10df954e=""
														   className="nameBox" style={{ color: 'rgb(255 16 103)' }}>${formatNumber(trade_commission)}</p>
														<span data-v-10df954e="" className="mt-3 valueBox">{t('trading_commissions')}</span>
														<div data-v-10df954e="" className="boxImg boxAmount"/>
													</div>
												</div>
												<div data-v-10df954e="" className="col-4">
													<div data-v-10df954e="" className="boxContent">
														<p data-v-10df954e="" className="nameBox">
															<span data-v-10df954e=""  style={{ color: 'rgb(10 186 255)' }}>${formatNumber(vip_commission)}</span>
														</p>
														<span data-v-10df954e="" className="mt-3 valueBox">{t('vip_lifting_commission')}</span>
														<div data-v-10df954e="" className="boxImg boxProfit"/>
													</div>
												</div>
												<div data-v-10df954e="" className="col-4 pl-0">
													<div data-v-10df954e="" className="boxContent">
														<p data-v-10df954e=""
														   className="nameBox d-flex">${formatNumber(trade_commission + vip_commission)}</p>
														<span data-v-10df954e=""
															  className="mt-3 valueBox">{t('total_commission')}</span>
														<div data-v-10df954e="" className="boxImg boxGain"/>
													</div>
												</div>
											</div>
										</div>
										:
										<div data-v-10df954e="" className="box">
											<div data-v-10df954e="" className="d-flex boxChild">
												<span data-v-10df954e="" className="name text-capitalize">{t('trading_commissions')}</span>
												<span data-v-10df954e=""
													  className="value ml-auto d-flex">${formatNumber(trade_commission)}</span>
											</div>
											<div data-v-10df954e="" className="d-flex boxChild">
												<span data-v-10df954e="" className="name text-capitalize">{t('vip_lifting_commission')}</span>
												<span data-v-10df954e=""
													  className="value white ml-auto">${formatNumber(vip_commission)}</span>
											</div>
											<div data-v-10df954e="" className="d-flex boxChild">
												<span data-v-10df954e="" className="name text-capitalize">{t('total_commission')}</span>
												<span data-v-10df954e="" className="value ml-auto d-flex">${formatNumber(trade_commission + vip_commission)}</span>
											</div>
										</div>
									}
								</div>
								<div data-v-10df954e="" className="container mt-5">
									<div data-v-10df954e="" className="d-flex">
										<img data-v-2eab6b18="" alt='' src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNSIgaGVpZ2h0PSIyMSIgdmlld0JveD0iMCAwIDI1IDIxIj4KICA8ZyBpZD0ic2VnbWVudGF0aW9uIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgwLjA0MSAtMSkiPgogICAgPHJlY3QgaWQ9IlJlY3RhbmdsZV80NjQyIiBkYXRhLW5hbWU9IlJlY3RhbmdsZSA0NjQyIiB3aWR0aD0iMTUiIGhlaWdodD0iNCIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTAuMDQxIDEpIiBmaWxsPSIjZmZmIi8+CiAgICA8cmVjdCBpZD0iUmVjdGFuZ2xlXzQ2NDMiIGRhdGEtbmFtZT0iUmVjdGFuZ2xlIDQ2NDMiIHdpZHRoPSI2IiBoZWlnaHQ9IjQiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDE4Ljk1OSAxKSIgZmlsbD0iIzIwNzdmYyIvPgogICAgPHJlY3QgaWQ9IlJlY3RhbmdsZV80NjQ0IiBkYXRhLW5hbWU9IlJlY3RhbmdsZSA0NjQ0IiB3aWR0aD0iMTUiIGhlaWdodD0iNCIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTAuMDQxIDE4KSIgZmlsbD0iI2ZmZiIvPgogICAgPHJlY3QgaWQ9IlJlY3RhbmdsZV80NjQ1IiBkYXRhLW5hbWU9IlJlY3RhbmdsZSA0NjQ1IiB3aWR0aD0iNiIgaGVpZ2h0PSI0IiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgxOC45NTkgMTgpIiBmaWxsPSIjMjA3N2ZjIi8+CiAgICA8cmVjdCBpZD0iUmVjdGFuZ2xlXzQ2NDYiIGRhdGEtbmFtZT0iUmVjdGFuZ2xlIDQ2NDYiIHdpZHRoPSIxNCIgaGVpZ2h0PSI1IiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgxMC45NTkgOSkiIGZpbGw9IiNmZmYiLz4KICAgIDxyZWN0IGlkPSJSZWN0YW5nbGVfNDY0NyIgZGF0YS1uYW1lPSJSZWN0YW5nbGUgNDY0NyIgd2lkdGg9IjYiIGhlaWdodD0iNSIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTAuMDQxIDkpIiBmaWxsPSIjMjA3N2ZjIi8+CiAgPC9nPgo8L3N2Zz4K" />
										<h1 data-v-10df954e="" className="title ml-3">{t('detail')}</h1>
										<div data-v-7a0c51c4="" className="d-lg-flex align-items-end ml-auto">
											<DateRangePicker handleChangeDateRange={this.handleChangeDateRange} initStart={commission.filter_from} initEnd={commission.filter_to} />
										</div>
									</div>
									<div data-v-10df954e="" className="mt-5 container-fluid">
										<div data-v-7ab9e0ba="" className="box-result" style={{ borderRadius: '3px' }}>
											<DataTable
												columns={this.columnsCommission}
												data={commission.data}
												pagination
												paginationServer
												paginationTotalRows={commission.total}
												onChangeRowsPerPage={this.handlePerRowsChangeCommission}
												onChangePage={this.handlePageChangeCommission}
												sortServer
												onSort={this.onSortTransaction}
											/>
										</div>
									</div>
								</div>
							</div> }
							{ selected_tab === 'network' && <div data-v-5a66dcfc='' className="page">
								<div data-v-5a66dcfc="" className="banner">
									<div data-v-5a66dcfc="" className="backgroundBanner" style={{ backgroundImage: `url('${asset_affiliate_network_banner}')` }} />
									<div data-v-5a66dcfc="" className="title">{t('level_map')}</div>
								</div>
								<div data-v-5a66dcfc='' className="pageContent network">
									<Network />
								</div>
							</div> }
						</div>
					</div>
				</div>
			</div>
		);
	}
}

function mapStateToProps(state) {
	return {
		memberReducer: state.memberReducer,
		appReducer: state.appReducer
	};
}

export default withTranslation()(connect(mapStateToProps, {
	fetchingUser,
	fetchingUserSuccess
})(withRouter(Affiliate)));
