import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import trans from './index';

const availableLanguages = ['en', 'vi', 'jp', 'kr', 'cn','cm', 'th', 'id', 'la'];

i18n.use(initReactI18next)
	.init({
		resources: trans.languages,
		lng: "en",
		fallbackLng: "vi",
		detection: {
			checkWhitelist: true
		},
		debug: false,
		whitelist: availableLanguages,
		interpolation: {
			escapeValue: false
		}
	});

export default i18n;
