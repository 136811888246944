import { Component } from 'react'
import InfoMobile from "./detail_mobile/info";
import BoardMobile from "./detail_mobile/board";
import {formatNumber, setClassNameCondition} from "../../../lib/helper";
import constants from "../../../lib/constants";
import Countdown from "./countdown";
import { withTranslation } from "react-i18next";

class DetailModalMobile extends Component{
	constructor(props) {
		super(props);
		this.state = {
			selected_tab: 'board',
		};
	}

	onChangeTab = (tab) => () => {
		this.setState({ selected_tab: tab });
	}

	render() {
		let { onClose, detail, t } = this.props;
		let { selected_tab } = this.state;
		let { type, schedule, value, total_reward, end, status } = (detail || {});
		return (
			<div data-v-2f4ce6a2="" role="document" className="modal-dialog">
				<div data-v-2f4ce6a2="" className="modal-content">
					<div data-v-2f4ce6a2="" className="modal-header"
						 style={{ backgroundImage: "url('images/BannerDetailPopup.jpeg')" }}>
						<div data-v-2f4ce6a2="" className="d-flex w-100 align-items-center">
							<label data-v-2f4ce6a2="" className="titleHeader mb-0 text-capitalize">Top {type === constants.CHALLENGE_TYPE.TRADING ? 'G.D' : 'VIP'} { schedule === constants.CHALLENGE_SCHEDULE.MONTH ? 'Tháng' : 'Tuần' } {value}</label>
							<button data-v-2f4ce6a2="" onClick={onClose} type="button" aria-label="Close" className="closeBtn ml-auto">
								<svg data-v-2f4ce6a2="" xmlns="http://www.w3.org/2000/svg" width="29" height="29"
									 viewBox="0 0 29 29">
									<g data-v-2f4ce6a2="" id="Group_21100" data-name="Group 21100"
									   transform="translate(-854.349 -108.349)">
										<circle data-v-2f4ce6a2="" id="Ellipse_1829" data-name="Ellipse 1829" cx="14.5"
												cy="14.5" r="14.5" transform="translate(854.349 108.349)" fill="#fff"
												opacity="0.219" />
										<path data-v-2f4ce6a2="" id="Path_2957" data-name="Path 2957"
											  d="M12.789,1.356a1.215,1.215,0,0,0-1.717,0l-4,4-4-4A1.214,1.214,0,1,0,1.356,3.073l4,4-4,4a1.214,1.214,0,1,0,1.717,1.717l4-4,4,4a1.214,1.214,0,1,0,1.717-1.717l-4-4,4-4A1.215,1.215,0,0,0,12.789,1.356Z"
											  transform="translate(861.61 115.958)" fill="#fff" />
									</g>
								</svg>
							</button>
						</div>
						{status === 'live'
							?
							<div data-v-2f4ce6a2="" className="countDown text-center">
								<label data-v-2f4ce6a2="">{t('time_remaining')}</label>
								<Countdown  countdownEnd={end} />
							</div>
							:
							<div data-v-27e0fb74="" className="challengeEnd text-center">
								<span data-v-27e0fb74="" className="text">{t('tournament_is_end')}</span>
							</div>
						}
					</div>
					<div data-v-2f4ce6a2="" className="modal-body">
						<div data-v-2f4ce6a2="" className="infoChallenge text-center d-flex">
							<div data-v-2f4ce6a2="" className="leftBox"><p data-v-2f4ce6a2=""
																		   className="price">${formatNumber(total_reward)}</p> <span
								data-v-2f4ce6a2="" className="textInfoLeft">{t('total_reward')}</span></div>
							<div data-v-2f4ce6a2="" className="rightBox"><p data-v-2f4ce6a2=""
																			className="titleRight text-capitalize">{type === constants.CHALLENGE_TYPE.TRADING ? 'KLGD' : 'Hoa hồng'}</p>
								<span data-v-2f4ce6a2="" className="textInfoRight">{t('tournament_type')}</span></div>
						</div>
						<div data-v-2f4ce6a2="" className="mainContent pt-3">
							<ul data-v-2f4ce6a2="" id="pills-tab" role="tablist"
								className="nav nav-pills menuHeaderLink text-center">
								<li data-v-2f4ce6a2="" className="nav-item">
									<a data-v-2f4ce6a2="" onClick={this.onChangeTab('info')} className={setClassNameCondition(selected_tab === 'info', 'active', '', 'nav-link')} style={{width: '95%'}}>{t('information')}</a>
								</li>
								<li data-v-2f4ce6a2="" className="nav-item">
									<a data-v-2f4ce6a2=""  onClick={this.onChangeTab('board')} className={setClassNameCondition(selected_tab === 'board', 'active', '', 'nav-link')} style={{width: '95%'}}>{t('board')}</a>
								</li>
							</ul>
							{ selected_tab === 'info' ? <InfoMobile detail={detail} /> : <BoardMobile detail={detail} /> }
						</div>
					</div>
				</div>
			</div>
		)
	}
}

export default withTranslation()(DetailModalMobile);
