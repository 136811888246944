import {isDesktop} from "../../../lib/helper";
import {Component} from "react";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";

class TradeButton extends Component{
	render() {
		let { is_bet, second, onBet, t } = this.props;
		if(isDesktop()){
			return <div data-v-05054441=""
						className="groupButton mt-lg-2">
				<div data-v-05054441=""
					 className={'pb-1 '}>
					<button data-v-05054441=""
							type="button"
							onClick={onBet('up')}
							disabled={!is_bet}
							className={'btn button btnSuccess w-100 ' + (!is_bet ? 'colorDisable' : '')}>
						<span data-v-05054441="" className="text-uppercase font-20">{ t('up') } </span>
						<svg data-v-05054441=""
							 xmlns="http://www.w3.org/2000/svg"
							 width="23.087"
							 height="11.668"
							 viewBox="0 0 23.087 11.668">
							<path data-v-05054441=""
								  id="Path_26233"
								  data-name="Path 26233"
								  d="M23.429,8H14.678l3.86,3.86-6.016,6.016L7.2,11.891a.728.728,0,0,0-1.025-.065l-5.834,5.1.96,1.1L6.592,13.4l5.353,6.022a.732.732,0,0,0,.524.245h.021a.727.727,0,0,0,.516-.214l6.563-6.563,3.86,3.86Z"
								  transform="translate(-0.342 -8)"
								  fill="#fff"/>
						</svg>
					</button>
				</div>
				<div data-v-05054441=""
					 className={'pb-1 '}><a
					data-v-05054441=""
					className="btn btnTransparent w-100 d-flex flex-column justify-content-center align-items-center"
					style={{cursor: 'default'}}>
					<p data-v-05054441=""
					   className="font-14 mb-0"
					   style={{lineHeight: 'normal'}}>{is_bet ?  t('place_orders') : t('wait_result')}</p>
					<p data-v-05054441="" className="font-18 mb-0 font-weight-700"
					   style={{lineHeight: 'normal'}}>
						{second}s
					</p>
				</a></div>
				<div data-v-05054441=""
					 className={'pb-1 '}>
					<button data-v-05054441=""
							type="button"
							onClick={onBet('down')}
							disabled={!is_bet}
							className={'btn button btnDown btnSuccess w-100 ' + (!is_bet ? 'colorDisable' : '')}>
						<span data-v-05054441="" className="text-uppercase font-20">{ t('down') } </span>
						<svg data-v-05054441=""
							 xmlns="http://www.w3.org/2000/svg"
							 width="23.587"
							 height="11.921"
							 viewBox="0 0 23.587 11.921">
							<g data-v-05054441=""
							   id="trend-down"
							   transform="translate(-0.342 -8)">
								<path data-v-05054441=""
									  id="Path_26234"
									  data-name="Path 26234"
									  d="M23.929,19.921H14.988l3.944-3.944L12.785,9.831,7.35,15.945a.744.744,0,0,1-1.048.066L.342,10.8l.981-1.122,5.4,4.729L12.2,8.25A.747.747,0,0,1,12.731,8h.022a.743.743,0,0,1,.527.218l6.705,6.705,3.944-3.944Z"
									  fill="#fff"/>
							</g>
						</svg>
					</button>
				</div>
			</div>
		}

		return <div data-v-05054441=""
					className={'groupButton mt-lg-2 row'}>
			<div data-v-05054441=""
				 className={'pb-1 col-4'}>
				<button data-v-05054441=""
						type="button"
						onClick={onBet('down')}
						disabled={!is_bet}
						className={'btn button btnDown btnSuccess w-100 ' + (!is_bet ? 'colorDisable' : '')}>
					<span data-v-05054441="" className="text-uppercase font-20">{ t('down') } </span>
					<svg data-v-05054441=""
						 xmlns="http://www.w3.org/2000/svg"
						 width="23.587"
						 height="11.921"
						 viewBox="0 0 23.587 11.921">
						<g data-v-05054441=""
						   id="trend-down"
						   transform="translate(-0.342 -8)">
							<path data-v-05054441=""
								  id="Path_26234"
								  data-name="Path 26234"
								  d="M23.929,19.921H14.988l3.944-3.944L12.785,9.831,7.35,15.945a.744.744,0,0,1-1.048.066L.342,10.8l.981-1.122,5.4,4.729L12.2,8.25A.747.747,0,0,1,12.731,8h.022a.743.743,0,0,1,.527.218l6.705,6.705,3.944-3.944Z"
								  fill="#fff"/>
						</g>
					</svg>
				</button>
			</div>
			<div data-v-05054441=""
				 className={'pb-1 col-4'}><a
				data-v-05054441=""
				className="btn btnTransparent w-100 d-flex flex-column justify-content-center align-items-center"
				style={{cursor: 'default'}}>
				<p data-v-05054441=""
				   className="font-14 mb-0"
				   style={{lineHeight: 'normal'}}>{is_bet ? t('place_orders') : t('wait_result')}</p>
				<p data-v-05054441="" className="font-18 mb-0 font-weight-700"
				   style={{lineHeight: 'normal'}}>
					{second}s
				</p>
			</a></div>
			<div data-v-05054441=""
				 className={'pb-1 col-4'}>
				<button data-v-05054441=""
						type="button"
						onClick={onBet('up')}
						disabled={!is_bet}
						className={'btn button btnSuccess w-100 ' + (!is_bet ? 'colorDisable' : '')}>
					<span data-v-05054441="" className="text-uppercase font-20">{ t('up') } </span>
					<svg data-v-05054441=""
						 xmlns="http://www.w3.org/2000/svg"
						 width="23.087"
						 height="11.668"
						 viewBox="0 0 23.087 11.668">
						<path data-v-05054441=""
							  id="Path_26233"
							  data-name="Path 26233"
							  d="M23.429,8H14.678l3.86,3.86-6.016,6.016L7.2,11.891a.728.728,0,0,0-1.025-.065l-5.834,5.1.96,1.1L6.592,13.4l5.353,6.022a.732.732,0,0,0,.524.245h.021a.727.727,0,0,0,.516-.214l6.563-6.563,3.86,3.86Z"
							  transform="translate(-0.342 -8)"
							  fill="#fff"/>
					</svg>
				</button>
			</div>
		</div>
	}
}

export default withTranslation()(connect(null, null)(TradeButton));

