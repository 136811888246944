import { Component } from 'react';
import {changeLanguage, setClassNameCondition} from "../../../lib/helper";
import { withTranslation } from "react-i18next";
import trans from "../../../lib/translations";
import i18n from "i18next";

class Footer extends Component{
	constructor(props) {
		super(props);
		this.state = {
			is_show_language: false,
		};
	}

	onShowLanguage = () => {
		this.setState({ is_show_language: !this.state.is_show_language });
	}

	onChangeLanguage  = (lang) => () => {
		this.setState({ is_show_language: false });
		changeLanguage(lang);
	}

	onClickDownloadIos = () => {
		window.location.href = `itms-services://?action=download-manifest&amp;url=https://${window.location.hostname}/app/ios/manifest.plist`;
	}

	render() {
		let {is_show_language} = this.state;
		return (
			<div data-v-24978cff="" data-v-5954443c="" className="footerFAQs">
				<div data-v-24978cff="" className="container position-relative">
					<div data-v-24978cff="" className="blockRow py-5 border-bottom">
						<div data-v-24978cff="" className="block">
							<div data-v-24978cff="" className={setClassNameCondition(is_show_language, 'show', '', 'dropdown language')}>
								<button data-v-24978cff="" type="button" id="dropdownMenuButton"
										onClick={this.onShowLanguage}
										data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
										className="btn dropdown-toggle colorBlack dropdownMenuButton"><img
									data-v-24978cff="" width='24' src={`images/flags/${i18n.language}.png`} alt=""
									className="mr-2" /> <span data-v-24978cff=""
															  className="colorBlack">{trans.languages[i18n.language].title}</span></button>

								<div data-v-24978cff="" aria-labelledby="dropdownMenuButton"
									 className={setClassNameCondition(is_show_language, 'show', '', 'dropdown-menu')}>
									{Object.values(trans.languages).map(({ title, id }, index) => {
										return <div data-v-24978cff="" onClick={this.onChangeLanguage(id)} key={index} className="dropdown-item pointer"
													style={{ display: id===i18n.language ? 'none' : 'block' }}><img data-v-24978cff="" width="24"
																													src={`images/flags/${id}.png`} alt={title} className="mr-2" />
											<span data-v-24978cff="">{title}</span>
										</div>
									})}
								</div>
							</div>
						</div>
					</div>
					<div data-v-24978cff="" className="blockRow py-5">
						<div data-v-24978cff="" className="block logo"><p data-v-24978cff="">
							<img
								data-v-24978cff="" width="208" height="35"
								src="images/logo.png"
								alt="" /></p>
							<p data-v-24978cff="" className="font-12">© 2020 Bitdiamon All rights
								reserved.</p></div>
						<div data-v-24978cff="" className="block"><p data-v-24978cff="">Risk
							Warning:
							Trading and investing in digital options involves significant level
							of risk
							and is not suitable and/or appropriate for all clients. Please make
							sure you
							carefully consider your investment objectives, level of experience
							and risk
							appetite before buying or selling any digital asset. You should be
							aware of
							and fully understand all the risks associated with trading and
							investing in
							digital assets, you should not invest funds you cannot afford to
							lose. You
							are granted limited non-exclusive rights to use the IP contained in
							this
							site for personal, non-commercial, non-transferable use only in
							relation to
							the services offered on the site</p></div>
					</div>
				</div>
			</div>
		);
	}
}


export default withTranslation()(Footer);
